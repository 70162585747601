import { type LoaderData, useLoaderData } from '@ubo/losse-sjedel'
import type { Component_Info } from '~/graphql/types'

interface InfoLoaderData extends Omit<LoaderData, 'rest'> {
  rest: {
    Info: {
      info: Component_Info
    }
  }
}

export default function useInfo(): Component_Info {
  const { rest } = useLoaderData<InfoLoaderData>()

  return rest.Info.info
}
