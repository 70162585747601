import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import { createContext, useContext } from 'react'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

const SectionContext = createContext<{
  fields: Page_Flexcontent_Flex_Content | null
}>({
  fields: null
})

export default function ContentQuote({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <SectionContext.Provider value={{ fields }}>
      <section data-component="ContentQuote" className="lg:pt-10 relative overflow-hidden">
        <div className="lg:hidden">
          <Mobile />
        </div>
        <div className="hidden lg:block">
          <Desktop />
        </div>
      </section>
    </SectionContext.Provider>
  )
}

function Mobile() {
  const { fields } = useContext(SectionContext)

  return (
    <div className={clsx(fields?.quote?.image && 'pt-10', !fields?.quote?.image && 'py-6 md:py-12', 'bg-hh-blue')}>
      <div className="container">
        <Content className="text-white children-headings:text-2xl children-headings:font-semibold mb-12">{fields?.description}</Content>
        <div className="pr-5">
          <QuoteDescription />
        </div>
        {(fields?.quote?.image || fields?.quote?.author) && (
          <div className="flex mt-3">
            {fields.quote.image && (
              <div className="w-3/5 md:w-2/5">
                <LossePlaatjie maxwidth={500} src={fields?.quote?.image} className="w-auto max-h-[425px]" />
              </div>
            )}
            {fields.quote.author && (
              <div className="w-2/5">
                <div className="mt-4 sm:mt-16">
                  <Author />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

function Desktop() {
  const { fields } = useContext(SectionContext)

  return (
    <div className={clsx(fields?.quote?.image && 'mt-24 bg-hh-blue', 'relative')}>
      <div className="relative container grid grid-cols-12 items-center">
        <div className="col-span-4 h-full">
          {(fields?.quote?.image || fields?.quote?.author) && (
            <div className="relative flex items-end h-full">
              {fields.quote.image && (
                <LossePlaatjie maxwidth={1000} src={fields?.quote?.image} className="absolute bottom-0 h-[140%] max-h-[425px] w-auto" />
              )}
              {fields.quote.author && (
                <div
                  className={clsx(
                    fields.quote.image ? 'bottom-12 hidden xl:block' : 'bg-hh-blue px-8 py-4 h-full z-20 hidden xl:flex items-end',
                    'absolute right-2 max-w-[115px]'
                  )}
                >
                  <Author />
                </div>
              )}
            </div>
          )}
        </div>
        <div className={clsx(!fields?.quote?.image && 'bg-hh-blue', 'col-span-8 relative')}>
          {!fields?.quote?.image && (
            <>
              <div className="absolute left-full h-full bg-hh-blue w-[2000px]" />
              <div className="absolute right-full h-full bg-hh-blue w-[75px]" />
            </>
          )}
          <div className="py-24">
            <QuoteDescription />
            <div className="xl:hidden pl-8 mt-4">
              <Author />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function Author() {
  const { fields } = useContext(SectionContext)

  return <span className="inline-block text-white pr-9 text-xl font-light">{fields?.quote?.author}</span>
}

function QuoteDescription() {
  const { fields } = useContext(SectionContext)

  return (
    <div className={clsx(fields?.quote?.image && 'max-lg:mt-8', 'relative pl-8')}>
      <svg
        className="absolute left-2 -top-2 lg:-top-8"
        width="35"
        height="28"
        viewBox="0 0 35 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.5"
          d="M20.5172 28H33.9138V13.5172H28.6035V11.8276C28.6035 7.72413 30.0517 6.39655 35 6.39655V0H32.5862C27.2759 0 23.6552 1.44827 21.9655 4.46552C20.8793 6.39655 20.5172 8.81034 20.5172 13.8793V28ZM0 28H13.3966V13.5172H8.08621V11.8276C8.08621 7.60344 9.53448 6.39655 14.6034 6.39655V0H12.069C6.75862 0 3.13793 1.44827 1.44828 4.46552C0.36207 6.39655 0 8.68965 0 13.8793V28Z"
          fill="white"
        />
      </svg>
      <Content className="relative text-xl lg:text-3xl children-p:font-hh-heading">{fields?.quote?.description}</Content>
    </div>
  )
}
