import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import ContentDefault from './ContentDefault'
import ContentQuote from './ContentQuote'
import ContentUsp from './ContentUsp'
import ContentBlocks from './ContentBlocks'
import ContentSliderBlocks from './ContentSliderBlocks'
import ContentImageSlider from './ContentImageSlider'
import ContentCTA from './ContentCTA'
import ContentAccordion from './ContentAccordion'
import ContentWithCutoutImage from './ContentWithCutoutImage'
import ContentWithBlockImage from './ContentWithBlockImage'
import ContentVideoSlider from './ContentVideoSlider'
import ContentSteps from './ContentSteps'
import ContentVacancy from './ContentVacancy'
import ContentVideo from './ContentVideo'

export default function ContentShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    default: ContentDefault,
    quote: ContentQuote,
    usp: ContentUsp,
    blocks: ContentBlocks,
    sliderblocks: ContentSliderBlocks,
    sliderimages: ContentImageSlider,
    cta: ContentCTA,
    accordion: ContentAccordion,
    cutoutimage: ContentWithCutoutImage,
    blockimage: ContentWithBlockImage,
    videoslider: ContentVideoSlider,
    steps: ContentSteps,
    vacancy: ContentVacancy,
    video: ContentVideo
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
