import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import VacanciesOverview from './VacanciesOverview'
import VacanciesCategoryPreview from './VacanciesCategoryPreview'
import VacanciesLatest from './VacanciesLatest'
import VacanciesPerCategory from './VacanciesPerCategory'
import VacanciesRelated from './VacanciesRelated'
import VacanciesChosen from './VacanciesChosen'

export default function VacanciesShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    overview: VacanciesOverview,
    categorypreview: VacanciesCategoryPreview,
    latest: VacanciesLatest,
    percategory: VacanciesPerCategory,
    related: VacanciesRelated,
    chosen: VacanciesChosen
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
