import { type LoaderData, useLoaderData, pageLoader as loader, useTransition } from '@ubo/losse-sjedel'
import { useEffect } from 'react'
import progress from 'nprogress'
import FlexContentHandler from '~/components/flex/FlexContentHandler'
import Layout from '~/components/layout/Layout'

export { loader }

export default function $page() {
  const {
    page: { contentType, flexContent }
  } = useLoaderData<LoaderData>()

  const transition = useTransition()

  useEffect(() => {
    if (transition.state === 'loading' || transition.state === 'submitting') {
      progress.start()
    } else {
      progress.done()
    }
  }, [transition.state])

  return (
    <Layout>
      <FlexContentHandler prefix={`${contentType?.node?.name}_Flexcontent`} flex={flexContent?.flex} />
    </Layout>
  )
}
