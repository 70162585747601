import { useState, useEffect, type PropsWithChildren } from 'react'
import type { LoaderData, } from '@ubo/losse-sjedel'
import { LosseLayout, useLoaderData } from '@ubo/losse-sjedel'
import Footer from './Footer'
import Header from './Header'
import SlideIn from '../elements/SlideIn'
import { useUtm } from '~/services/utm'

export default function Layout({ children }: PropsWithChildren<{}>) {
  // const { page } = useLoaderData<LoaderData>()
  // const [isSlideOpen, setIsSlideOpen] = useState(false)

  useUtm()

  // useEffect(() => {
  //   setTimeout(() => {
  //     !sessionStorage.getItem('hide') && setIsSlideOpen(true)
  //   }, 2500)
  // }, [])

  // function closeSlideModal() {
  //   sessionStorage.setItem('hide', 'true')
  //   setIsSlideOpen(false)
  // }


  return (
    <LosseLayout>
      <Header />
      <main className="min-h-screen pt-[70px] lg:pt-[100px]">{children}</main>
      <Footer />
      {/* {page?.databaseId === 43 && (
        <SlideIn isOpen={isSlideOpen} requestClose={() => closeSlideModal()} bgColor="#FFF">
          <div>
            <a href="/open-avond/"><img src="/images/Popup website-1303-02-min.jpg" alt="Open Avond" /></a>
          </div>
        </SlideIn>
      )} */}
    </LosseLayout>
  )
}
