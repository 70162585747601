import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentUsp({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentUsp" className="bg-hh-mine-shaft section">
      <div className="container flex flex-col gap-12 lg:gap-28">
        <Content className="max-md:children-headings:text-3xl content text-white">{fields.description}</Content>
        <div className="flex lg:flex-row flex-col justify-center gap-12 lg:gap-16">
          {fields.items?.map((item, index) => (
            <div className="flex flex-col gap-4 lg:gap-5" key={index}>
              <div className="flex justify-center">
                <LossePlaatjie className="w-[110px] md:w-[140px]" maxwidth={140} src={item?.icon} alt="icon" />
              </div>
              <div>
                <Content className="children-p:font-hh-heading children-p:font-bold children-p:text-xl lg:children-p:text-2xl text-white">
                  {item?.description}
                </Content>
              </div>
            </div>
          ))}
        </div>
        {fields.links && (
          <div className="flex justify-center items-center gap-2 my-4">
            {fields.links.map((link, index) => (
              <Button className={clsx(index >= 1 && 'button-white-opaque', 'button-white-opaque')} key={index} to={link?.link?.url || '/'}>
                {link?.link?.title}
              </Button>
            ))}
          </div>
        )}
      </div>
    </section>
  )
}
