import { LossePlaatjie } from '@ubo/losse-sjedel'
import { Navigation, Pagination } from 'swiper/modules'
import Content from '~/components/elements/Content'
import Slider, { Slide } from '~/components/elements/Slider'
import type { Page_Flexcontent_Flex_Projects, Project } from '~/graphql/types'

export default function ProjectsSlider({ fields }: { fields: Page_Flexcontent_Flex_Projects }) {
  return (
    <section data-component="ProjectsSlider" className="section">
      <div className="container">
        <div className="flex flex-col gap-1 sm:gap-6">
          <Content className="content-md max-md:px-4 children-p:text-hh-blue children-p:text-base max-md:children-headings:!text-left max-md:children-p:!text-left md:children-p:text-2xl children-p:font-hh-heading">
            {fields.description}
          </Content>
          <div>
            <Slider
              modules={[Pagination, Navigation]}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 10
                }
              }}
              pagination={{
                clickable: true,
                bulletClass: 'swiper-pagination-bullet-dark',
                el: '.project-slider-pagination'
              }}
            >
              {fields?.projects?.edges.map((item, index) => {
                const project = item?.node as Project

                return (
                  <Slide key={index} className="py-5 cursor-grab sm:py-10 !mb-4">
                    <div className="grid grid-cols-5 lg:grid-cols-10">
                      <div className="col-span-5 lg:col-span-6">
                        <LossePlaatjie
                          maxwidth={1000}
                          className="h-[225px] md:h-[400px] xl:h-[512px] object-cover"
                          src={project.featuredImage?.node}
                          alt="featured"
                        />
                      </div>
                      <div className="col-span-5 lg:col-span-4 flex justify-center items-center">
                        <div className="bg-hh-mine-shaft bg-opacity-80 mix-blend-multiply xl:pb-20 px-6 md:px-14 pb-6 md:pb-10 py-6 lg:p-8 xl:p-10 w-[90%] lg:w-full text-center">
                          <h3 className="font-hh-heading font-bold mb-3 md:mb-8 text-white text-2xl md:text-4xl xl:text-5xl uppercase">
                            {project.title}
                          </h3>
                          <div className="grid grid-cols-2 gap-x-8 lg:gap-x-0 gap-y-3 xl:gap-y-5 text-left">
                            <div className="text-white/50 text-sm md:text-xl xl:text-2xl font-hh-heading font-normal">Lengte</div>
                            <div className="text-white text-sm md:text-xl xl:text-2xl font-hh-heading font-normal">
                              {project.recap?.length}
                            </div>
                            <div className="text-white/50 text-sm md:text-xl xl:text-2xl font-hh-heading font-normal">Eigenaar</div>
                            <div className="text-white text-sm md:text-xl xl:text-2xl font-hh-heading font-normal">
                              {project.recap?.owner}
                            </div>
                            <div className="text-white/50 text-sm md:text-xl xl:text-2xl font-hh-heading font-normal">Scheepswerf</div>
                            <div className="text-white text-sm md:text-xl xl:text-2xl font-hh-heading font-normal">
                              {project.recap?.shipyard}
                            </div>
                            <div className="text-white/50 text-sm md:text-xl xl:text-2xl font-hh-heading font-normal">Type</div>
                            <div className="text-white text-sm md:text-xl xl:text-2xl font-hh-heading font-normal">
                              {project.recap?.type}
                            </div>
                            <div className="text-white/50 text-sm md:text-xl xl:text-2xl font-hh-heading font-normal">Jaar verbouwing</div>
                            <div className="text-white text-sm md:text-xl xl:text-2xl font-hh-heading font-normal">
                              {project.recap?.buildyear}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Slide>
                )
              })}
              <div className="flex justify-center">
                <div className="project-slider-pagination"></div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </section>
  )
}
