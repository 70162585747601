import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentWithBlockImage({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentWithBlockImage" className="relative max-w-[1700px] mx-auto">
      <div className="md:grid grid-cols-1 md:grid-cols-2 pointer-events-none hidden absolute w-full h-full top-0 left-0">
        <div className="col-span-1 relative">
          <LossePlaatjie maxwidth={1000} className="absolute h-full w-full top-0 left-0 object-cover" src={fields.image} alt="block" />
        </div>
      </div>
      <div className="container">
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="col-span-1" />
          <div className="col-span-1 section md:px-10 lg:px-24 flex flex-col gap-6 lg:gap-20">
            <Content className="content lg:content-lg children-headings:mb-4 lg:children-headings:mb-12">{fields.description}</Content>
            {fields.links && (
              <div className="flex max-md:justify-center items-center gap-2">
                {fields.links.map((link, index) => (
                  <Button
                    className={clsx(index >= 1 && 'button-mine-shaft-opaque', 'button-mine-shaft-opaque')}
                    key={index}
                    to={link?.link?.url || '/'}
                  >
                    {link?.link?.title}
                  </Button>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <LossePlaatjie maxwidth={1000} className="md:hidden block" src={fields.image} alt="block" />
    </section>
  )
}
