import Content from '~/components/elements/Content'
import type { Component_Info, Page_Flexcontent_Flex_Banner, Vacature } from '~/graphql/types'
import { LosseLink } from '@ubo/losse-sjedel'
import useInfo from '~/hooks/useInfo'

export default function BannerVideo({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  const info = useInfo()

  const defaultVacanciesTotal = info.vacancies?.edges.filter((edge) => {
    const vacancy = edge.node as Vacature

    const mainCategory = vacancy.categories?.edges.filter((edge) => edge.node.parentDatabaseId === 12)[0].node.databaseId

    return mainCategory === 15
  }).length

  return (
    <section data-component="BannerVideo">
      <div className="relative overflow-hidden containerize lg:flex flex flex-col justify-end bg-hh-mine-shaft h-[92.2dvh] min-h-[600px] max-h-[1160px] ">
        <video muted autoPlay loop playsInline className="min-w-full min-h-full w-auto absolute object-cover h-auto top-0">
          <source src={fields.video || '/'} type="video/mp4" />
        </video>
        <div className="absolute inset-0 z-10 bg-gradient-to-b from-transparent to-black/60 lg:to-black/40 block" />
        <div className="relative z-20 container max-w-4xl mx-auto mt-auto pt-10 pb-28 lg:pb-40 lg:pt-16">
          <Content className="mb-11 lg:mb-20 content-lg children-p:font-hh-heading children-headings:text-white children-p:text-white children-h1:text-4xl lg:children-h1:text-6xl children-p:text-lg lg:children-p:text-2xl children-a:block sm:children-a:inline-flex">
            {fields.description}
          </Content>
          <div className="flex items-center gap-3 justify-center">
            {fields.links?.map((link, index) => (
              <LosseLink className="button-blue" to={link?.link?.url} key={index}>
                {link?.link?.title?.replace(/\{info\.(\w+)\}/g, (match: any, variable: any) => {
                  if (info.hasOwnProperty(variable)) {
                    return info[variable as keyof Component_Info]
                  }

                  if (variable === 'total') {
                    return defaultVacanciesTotal
                  }

                  return match
                })}
              </LosseLink>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
