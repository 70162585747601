import SwiperCore from 'swiper'
import { Autoplay, EffectCreative, EffectCube, FreeMode, Navigation, Pagination } from 'swiper/modules'
import type { SwiperProps } from 'swiper/react'
import { Swiper, SwiperSlide } from 'swiper/react'

SwiperCore.use([Autoplay, FreeMode, EffectCube, Pagination, EffectCreative, Navigation])

export const Slide = SwiperSlide

export default function Slider(props: SwiperProps) {
  return (
    <>
      <Swiper {...props}>{props.children}</Swiper>
    </>
  )
}
