import clsx from 'clsx'
import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'
import useInfo from '~/hooks/useInfo'
import { replaceInfoVariables } from '~/services/utils'

export default function ContentCTA({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const info = useInfo()
  return (
    <section data-component="ContentCTA" className="section">
      <div className="container">
        <div className="max-w-screen-lg mx-auto">
          <div className="grid grid-cols-1 lg:gap-0 gap-6 sm:gap-8 lg:grid-cols-2">
            <Content className="content-lg text-center lg:text-left">{fields.description}</Content>
            {fields.links && (
              <div className="flex items-center justify-center gap-2 mt-4">
                {fields.links.map((link, index) => (
                  <Button className={clsx(index >= 1 && 'button-blue', 'button-blue')} key={index} to={link?.link?.url || '/'}>
                    {replaceInfoVariables(link?.link?.title || '', info)}
                  </Button>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
