import { LosseFlexShell } from '@ubo/losse-sjedel'
import type { LosseFlexShellProps, LosseFlexShellType } from '@ubo/losse-sjedel'
import ProjectsSlider from './ProjectsSlider'

export default function ProjectsShell({ fields, index }: LosseFlexShellProps) {
  const types: LosseFlexShellType = {
    slider: ProjectsSlider
  }

  return <LosseFlexShell types={types} fields={fields} index={index} />
}
