import { useState } from 'react';
import Form from '~/components/elements/Form'
import Content from "~/components/elements/Content";
import Loading from "~/components/elements/Loading";
import type { Page_Flexcontent_Flex_Form } from "~/graphql/types";


export default function FormOpenAvond({ fields }: { fields: Page_Flexcontent_Flex_Form }) {
  const [formStatus, setFormStatus] = useState('')

  return (
    <section id="formulier" data-component="FormOpenAvond" className="section py-10 lg:py-20">
      <div className="container">
        <div className="lg:w-1/2 mx-auto">
          <div>
            <Content className="children-headings:text-2xl lg:children-headings:text-4xl mb-6 lg:mb-12">{fields?.description}</Content>
            <Form
              className="relative"
              generate
              data={fields?.form}
              renderLoading={() => <Loading className="text-hh-blue" />}
              renderStatus={({ status, content }) => {
                setFormStatus(status)

                return <Content>{content}</Content>
              }}
            />
          </div>
        </div>
      </div>
    </section>
  )
}
