import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Banner } from '~/graphql/types'
import { LossePlaatjie } from '@ubo/losse-sjedel'

export default function BannerDefaultSmall({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  return (
    <section data-component="BannerDefaultSmall" className="lg:pt-16 xl:pt-20 container-lg max-lg:px-0 max-lg:pt-0">
      {fields?.image && (
        <div className="w-full overflow-hidden relative">
          <LossePlaatjie
            maxwidth={2000}
            loading="eager"
            placeholder="blur"
            className="w-full h-full object-cover min-h-[320px] lg:min-h-[500px]"
            src={fields.image}
          />
          {fields?.description && (
            <div className="flex w-full justify-center">
              <div className="absolute bottom-10 lg:bottom-32 pt-5">
                <Content
                  style={{ textShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)' }}
                  className="children-headings:mb-6 children-h2:text-lg lg:children-h2:text-3xl children-h2:font-light children-h1:text-6xl lg:children-h1:text-7xl children-h1:font-semibold text-white"
                >
                  {fields?.description}
                </Content>
              </div>
            </div>
          )}
        </div>
      )}
    </section>
  )
}
