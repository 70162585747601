import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

const gridLiteral: {
  [key: number]: string
} = {
  0: '[grid-area:1/1/3/6] lg:[grid-area:1/1/3/6]',
  1: '[grid-area:1/6/2/13] lg:[grid-area:1/6/2/11]',
  2: '[grid-area:2/6/3/13] lg:[grid-area:2/6/3/11]',
  3: '[grid-area:3/1/5/7] lg:[grid-area:3/1/5/5]',
  4: '[grid-area:3/7/5/13] lg:[grid-area:3/5/5/11]'
}

const colorLiteral: {
  [key: string]: string
} = {
  white: 'bg-[#D9D9D9] lg:bg-white',
  lightgray: 'bg-[#F3F3F3]',
  dark: 'bg-hh-mine-shaft',
  blue: 'bg-hh-blue',
  lightblue: 'bg-[#D3ECFB]'
}

export default function ContentBlocks({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentBlocks" className="lg:bg-hh-mine-shaft section">
      <div className="container">
        <div className="grid grid-cols-5 gap-5 sm:gap-8">
          <div className="col-span-5 lg:col-span-2">
            <Content className="content-xl lg:children-br:inline-block children-br:hidden children-p:font-hh-heading children-p:pt-2 md:children-p:pt-5 lg:children-p:pt-10 children-p:text-base children-p:!leading-[1] sm:children-p:text-xl md:children-p:text-3xl children-p:text-hh-blue lg:text-white">
              {fields.description}
            </Content>
          </div>
          <div className="col-span-5 lg:col-span-3">
            <div className="grid gap-3 sm:gap-5 lg:gap-6 h-full grid-cols-12 lg:grid-cols-10">
              {fields.items?.map((item, index) => (
                <LosseLink
                  to={item?.link?.url}
                  className={clsx(
                    gridLiteral[index],
                    item?.color && colorLiteral[item.color],
                    [0, 3].includes(index) && 'h-[200px] md:h-[260px]',
                    'relative block'
                  )}
                  key={index}
                >
                  {item?.image && (
                    <>
                      <LossePlaatjie
                        maxwidth={1000}
                        src={item.image}
                        alt="cover"
                        className="lg:block hidden absolute top-0 left-0 z-10 h-full w-full object-cover"
                      />
                      <div className="lg:block hidden absolute top-0 left-0 h-full w-full z-20 bg-[#6F6F6F] mix-blend-multiply" />
                    </>
                  )}
                  <div className={clsx('relative h-full px-3 md:px-4 py-4 md:py-5 z-40 flex justify-start items-end')}>
                    <div
                      className={clsx(
                        index === 1 && 'flex-row-reverse gap-5',
                        [1, 2].includes(index) ? 'flex items-end gap-3 flex-row' : 'flex gap-3 flex-col'
                      )}
                    >
                      <LossePlaatjie maxwidth={1000} className="!max-w-[60%] lg:max-w-full" src={item?.icon} alt="icon" />
                      <Content
                        className={clsx(
                          item?.image && item.color === 'dark' && 'text-white',
                          item?.image && item.color !== 'dark' && 'lg:text-white',
                          'children-p:font-hh-heading children-p:text-base md:children-p:text-lg children-p:!leading-[1] children-p:font-bold'
                        )}
                      >
                        {item?.description}
                      </Content>
                    </div>
                  </div>
                </LosseLink>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
