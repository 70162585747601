import { LosseLink, LossePlaatjie, losseContentParse } from '@ubo/losse-sjedel'
import type { Vacature } from '~/graphql/types'
import Content from '../Content'
import Button from '../Button'
import useInfo from '~/hooks/useInfo'

export default function Preview({ fields }: { fields: Vacature }) {
  const level = fields.categories?.edges.find((edge) => edge.node.parentDatabaseId === 6)

  const cleanExcerpt = fields.recap?.excerpt?.replace(/(<([^>]+)>)/gi, '')
  const info = useInfo()

  return (
    <section id="Preview" className="h-full relative flex flex-col">
      <div className="group overflow-hidden lg:block hidden lg:min-h-[350px] xl:min-h-[410px]">
        <LosseLink to={fields.uri || '/'}>
          <LossePlaatjie
            src={fields.featuredImage?.node ?? info.defaultVacancyImage}
            alt="featured"
            className="h-full w-full object-cover group-hover:scale-105 transition-transform"
          />
        </LosseLink>
      </div>
      <div className="px-4 py-6 md:p-9 bg-hh-concrete flex h-full flex-col gap-6 justify-between">
        <div className="flex flex-col gap-3 sm:gap-6 swiper-no-swiping">
          <h3 className="font-hh-heading font-bold uppercase text-hh-mine-shaft text-xl leading-[1] md:text-2xl lg:text-3xl xl:text-4xl">
            {fields.title}
          </h3>
          {fields.recap?.salary && (
            <div className="flex">
              <div className="bg-hh-blue text-xs sm:text-base md:text-lg font-hh-heading text-white rounded-full px-[14px] py-[4px]">
                {fields.recap.salary}
              </div>
            </div>
          )}
          {(fields.recap?.location || level || fields.recap?.hours) && (
            <div className="flex flex-row sm:flex-col lg:flex-row gap-4 lg:gap-6">
              {fields.recap?.location && (
                <div className=" flex items-center flex-row gap-2 font-hh-heading text-sm sm:text-base lg:text-xl text-hh-mine-shaft/50">
                  <div className="w-6">
                    <svg width="18" height="25" viewBox="0 0 18 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="Group">
                        <path
                          id="Vector"
                          d="M8.43777 24.2219C8.56988 24.42 8.79232 24.5391 9.03048 24.5391C9.26864 24.5391 9.49108 24.4201 9.62319 24.2219C11.309 21.6932 13.7921 18.5703 15.5224 15.3941C16.906 12.8546 17.5785 10.6887 17.5785 8.77267C17.5785 4.05927 13.7439 0.224609 9.03048 0.224609C4.31708 0.224609 0.482422 4.05927 0.482422 8.77267C0.482422 10.6887 1.15501 12.8546 2.53856 15.3941C4.2676 18.5678 6.75542 21.6984 8.43777 24.2219ZM9.03048 1.64929C12.9583 1.64929 16.1539 4.84484 16.1539 8.77267C16.1539 10.4445 15.5381 12.3875 14.2713 14.7126C12.7799 17.4503 10.6515 20.2274 9.03048 22.5621C7.40972 20.2277 5.28121 17.4504 3.78962 14.7126C2.52289 12.3875 1.9071 10.4445 1.9071 8.77267C1.9071 4.84484 5.10265 1.64929 9.03048 1.64929Z"
                          fill="#00A1FF"
                        />
                        <path
                          id="Vector_2"
                          d="M9.03087 13.0467C11.3876 13.0467 13.3049 11.1293 13.3049 8.77264C13.3049 6.41594 11.3876 4.49861 9.03087 4.49861C6.67417 4.49861 4.75684 6.41594 4.75684 8.77264C4.75684 11.1293 6.67417 13.0467 9.03087 13.0467ZM9.03087 5.92329C10.602 5.92329 11.8802 7.20151 11.8802 8.77264C11.8802 10.3438 10.602 11.622 9.03087 11.622C7.45973 11.622 6.18151 10.3438 6.18151 8.77264C6.18151 7.20151 7.45973 5.92329 9.03087 5.92329Z"
                          fill="#00A1FF"
                        />
                      </g>
                    </svg>
                  </div>
                  {fields.recap.location}
                </div>
              )}
              {fields.recap?.hours && (
                <div className=" flex items-center flex-row gap-2 font-hh-heading text-sm sm:text-base lg:text-xl text-hh-mine-shaft/50">
                  <div className="w-6">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="Three o Clock clock" clipPath="url(#clip0_1396_2541)">
                        <g id="Group">
                          <g id="Group_2">
                            <path
                              id="Vector"
                              d="M12.4046 0.224609C6.0961 0.224609 0.962891 5.3574 0.962891 11.6667C0.962891 17.976 6.0961 23.1088 12.4046 23.1088C18.7143 23.1088 23.8467 17.976 23.8467 11.6667C23.8467 5.3574 18.7143 0.224609 12.4046 0.224609ZM12.4046 21.5833C6.93676 21.5833 2.48842 17.135 2.48842 11.6667C2.48842 6.19848 6.93717 1.75014 12.4046 1.75014C17.8732 1.75014 22.3212 6.19848 22.3212 11.6667C22.3212 17.135 17.8736 21.5833 12.4046 21.5833Z"
                              fill="#00A1FF"
                            />
                            <path
                              id="Vector_2"
                              d="M18.0721 10.8384H13.1683V4.35436C13.1683 3.933 12.8267 3.59138 12.4057 3.59138C11.9852 3.59138 11.6436 3.93341 11.6436 4.35436V12.3635H18.0721C18.493 12.3635 18.8346 12.0223 18.8342 11.6009C18.8342 11.18 18.4922 10.8384 18.0721 10.8384Z"
                              fill="#00A1FF"
                            />
                          </g>
                        </g>
                      </g>
                      <defs>
                        <clipPath id="clip0_1396_2541">
                          <rect width="22.8842" height="22.8842" fill="white" transform="translate(0.962891 0.224609)" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  {fields.recap.hours}
                </div>
              )}
              {level && (
                <div className=" flex items-center flex-row gap-2 font-hh-heading text-sm sm:text-base lg:text-xl text-hh-mine-shaft/50">
                  <div className="w-6 pt-[5px]">
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="Diploma" clipPath="url(#clip0_1396_2533)">
                        <path
                          id="Vector"
                          d="M24.1256 0.224762H1.23578C0.842375 0.224762 0.523438 0.5437 0.523438 0.9371V20.0119C0.523438 20.4054 0.842375 20.7243 1.23578 20.7243H15.0203V23.8269C15.0203 24.0738 15.1481 24.3031 15.3582 24.4329C15.5682 24.5626 15.8303 24.5744 16.0512 24.4641L18.0216 23.4789L19.9921 24.4641C20.2129 24.5745 20.4751 24.5627 20.6851 24.4329C20.8952 24.3031 21.023 24.0738 21.023 23.8269V20.7243H24.1256C24.519 20.7243 24.8379 20.4054 24.8379 20.0119V0.9371C24.8379 0.5437 24.519 0.224762 24.1256 0.224762ZM18.0216 17.7736C16.7316 17.7736 15.682 16.724 15.682 15.4339C15.682 14.1439 16.7316 13.0943 18.0216 13.0943C19.3117 13.0943 20.3613 14.1439 20.3613 15.434C20.3613 16.7241 19.3117 17.7736 18.0216 17.7736ZM19.5983 22.6743L18.3402 22.0453C18.1397 21.945 17.9036 21.945 17.7031 22.0453L16.445 22.6743V18.8509C16.925 19.0733 17.4589 19.1983 18.0216 19.1983C18.5844 19.1983 19.1184 19.0733 19.5983 18.8509V22.6743ZM23.4132 19.2996H21.023V17.7028C21.5014 17.0715 21.7859 16.2854 21.7859 15.434C21.7859 13.3583 20.0973 11.6697 18.0216 11.6697C15.9459 11.6697 14.2573 13.3583 14.2573 15.434C14.2573 16.2854 14.5418 17.0715 15.0203 17.7028V19.2996H1.94811V1.64944H23.4132V19.2996Z"
                          fill="#00A1FF"
                        />
                        <path
                          id="Vector_2"
                          d="M8.10296 6.22708H17.2589C17.6523 6.22708 17.9712 5.90814 17.9712 5.51474C17.9712 5.12134 17.6523 4.8024 17.2589 4.8024H8.10296C7.70956 4.8024 7.39062 5.12134 7.39062 5.51474C7.39062 5.90814 7.70952 6.22708 8.10296 6.22708Z"
                          fill="#00A1FF"
                        />
                        <path
                          id="Vector_3"
                          d="M5.81292 10.0425H19.5468C19.9402 10.0425 20.2591 9.72357 20.2591 9.33017C20.2591 8.93677 19.9402 8.61783 19.5468 8.61783H5.81292C5.41952 8.61783 5.10059 8.93677 5.10059 9.33017C5.10059 9.72357 5.41952 10.0425 5.81292 10.0425Z"
                          fill="#00A1FF"
                        />
                        <path
                          id="Vector_4"
                          d="M5.81292 13.0943H11.9169C12.3103 13.0943 12.6292 12.7753 12.6292 12.3819C12.6292 11.9885 12.3103 11.6696 11.9169 11.6696H5.81292C5.41952 11.6696 5.10059 11.9885 5.10059 12.3819C5.10059 12.7753 5.41952 13.0943 5.81292 13.0943Z"
                          fill="#00A1FF"
                        />
                        <path
                          id="Vector_5"
                          d="M5.81292 16.146H11.9169C12.3103 16.146 12.6292 15.8271 12.6292 15.4337C12.6292 15.0403 12.3103 14.7213 11.9169 14.7213H5.81292C5.41952 14.7213 5.10059 15.0403 5.10059 15.4337C5.10059 15.8271 5.41952 16.146 5.81292 16.146Z"
                          fill="#00A1FF"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1396_2533">
                          <rect width="24.3145" height="24.3145" fill="white" transform="translate(0.523438 0.224762)" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  {level.node.name}
                </div>
              )}
            </div>
          )}

          <div className="overflow-hidden">
            <Content className="line-clamp-5 overflow-hidden !leading-[18px] sm:!leading-[26px] text-xs sm:text-sm lg:text-base text-hh-mine-shaft">
              <p>{losseContentParse(cleanExcerpt)}</p>
            </Content>
          </div>
        </div>

        <div className="flex justify-end">
          <Button to={fields.uri || '/'} className="button-green">
            BEKIJK VACATURE
          </Button>
        </div>
      </div>
    </section>
  )
}
