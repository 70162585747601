import { type LoaderData, useLosseLayout } from '@ubo/losse-sjedel'
import { LosseLink } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import type { Component_Header } from '~/graphql/types'
import HeaderDesktop from './HeaderDesktop'
import HeaderMobile from './HeaderMobile'

export interface HeaderLoaderData extends Omit<LoaderData, 'header'> {
  header: Component_Header
}

export default function Header() {
  const { isSticky } = useLosseLayout()

  return (
    <nav className={clsx(isSticky && 'shadow', 'fixed left-0 top-0 right-0 z-50 h-[70px] lg:h-[100px] bg-black transition-all')}>
      <div className="container-lg flex h-full lg:py-0 py-2 items-end lg:items-center justify-between">
        <LosseLink to="/" aria-label="Home">
          <svg
            className="h-[46px] lg:h-[55px] w-[113px] lg:w-[157px]"
            width="158"
            height="55"
            viewBox="0 0 158 55"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_1260_771)">
              <path
                d="M102.761 15.9854L101.765 17.3562L99.5768 13.7289H97.6923L95.6778 10.0805H92.0387L90.1541 7.5498H85.0637L83.2009 10.0805H80.9048V28.5122H103.259L115.433 16.0065L102.761 15.9854ZM81.5546 10.7131H83.5475L85.4103 8.18247H89.8292L91.7138 10.7131H95.2879L97.3024 14.3615H99.1869L101.331 17.9045L100.573 18.9589H81.533V10.7131H81.5546ZM102.978 27.8796H81.5546V19.6127H100.92L103.086 16.6181H113.895L102.978 27.8796Z"
                fill="white"
              />
              <path
                d="M52.4417 10.0806L41.6978 20.8359H46.0733V28.4912H76.2258V10.0806H52.4417ZM75.5543 27.8796H46.7232V20.2244H43.2357L52.5717 10.8819L61.1062 19.6339H75.576V27.8796H75.5543ZM75.5543 18.9801H61.3662L53.3298 10.7132H75.5543V18.9801Z"
                fill="white"
              />
              <path
                d="M61.1929 0V5.84164H56.1458V0H39.9648V37.1166H56.1458V31.2749H61.1929V37.1166H77.2872V0H61.1929ZM75.5543 35.4295H62.9258V29.5878H54.4129V35.4295H41.6977V1.68712H54.4129V7.52876H62.9258V1.68712H75.5543V35.4295Z"
                fill="white"
              />
              <path d="M75.5545 10.7134V18.9803H61.3664L53.3301 10.7134H75.5545Z" fill="#10944B" />
              <path d="M75.5544 19.6128V27.8796H46.7233V20.2032H43.2358L52.5718 10.8608L61.1064 19.6128H75.5544Z" fill="#10944B" />
              <path
                d="M101.05 0V5.84164H96.0027V0H79.8218V37.1166H96.0027V31.2749H101.05V37.1166H117.144V0H101.05ZM115.411 35.4295H102.783V29.5878H94.2698V35.4295H81.5547V1.68712H94.2698V7.52876H102.783V1.68712H115.411V35.4295Z"
                fill="white"
              />
              <path d="M113.895 16.618L102.978 27.8795H81.5547V19.6127H100.92L103.086 16.618H113.895Z" fill="#1296D3" />
              <path
                d="M101.353 17.9257L100.595 18.9802H81.5547V10.7133H83.5475L85.4104 8.18262H89.8293L91.7138 10.7133H95.2879L97.3241 14.3828H99.2086L101.353 17.9257Z"
                fill="#1296D3"
              />
              <path
                d="M0 47.1128H2.44772V49.8965H7.62476V47.1128H10.0725V54.8946H7.62476V51.9422H2.44772V54.8946H0V47.1128Z"
                fill="white"
              />
              <path
                d="M11.7402 47.1128H20.4914V48.9264H14.188V50.1707H20.1448V51.7735H14.188V53.0177H20.6214V54.8946H11.7402V47.1128Z"
                fill="white"
              />
              <path d="M22.2461 47.1128H24.6938V54.8946H22.2461V47.1128Z" fill="white" />
              <path
                d="M26.4699 47.1128H30.2606L34.8745 52.7646H34.8961V47.1128H37.3439V54.8946H33.5315L28.9176 49.2428H28.896V54.8946H26.4482V47.1128H26.4699Z"
                fill="white"
              />
              <path
                d="M39.0117 47.1128H47.7629V48.9264H41.4594V50.1707H47.4379V51.7735H41.4594V53.0177H47.8928V54.8946H39.0117V47.1128Z"
                fill="white"
              />
              <path
                d="M49.3877 47.1128H53.1784L57.7923 52.7646H57.8139V47.1128H60.2616V54.8946H56.4709L51.8571 49.2428H51.8354V54.8946H49.3877V47.1128Z"
                fill="white"
              />
              <path
                d="M67.3018 52.1741C67.3234 50.951 67.67 50.5081 69.0347 50.4659V50.4448C68.2549 49.9809 68.2115 49.7278 68.2115 48.7999C68.2115 47.3026 68.6014 47.0073 71.0925 47.0073H72.9987C75.793 47.0073 76.2479 47.3447 76.2479 49.2006V49.6224H74.1251V49.2428C74.1251 48.821 73.8435 48.6101 73.042 48.6101H71.3524C70.6592 48.6101 70.486 48.6944 70.486 49.074C70.486 49.3693 70.5293 49.4747 71.0058 49.7278L74.9915 51.7312V50.6979H76.9843V51.7945C76.9888 52.0989 76.9597 52.4029 76.8977 52.7013L78.3273 53.4184L77.6558 54.8735L76.2479 54.1565C75.7713 54.8946 74.6233 54.9579 73.5835 54.9579H70.2693C68.4715 54.9579 67.3018 54.515 67.3018 52.7435V52.1741ZM74.2117 53.3129C74.2205 53.3139 74.2294 53.313 74.2377 53.3102C74.2461 53.3074 74.2537 53.3027 74.26 53.2966C74.2662 53.2905 74.271 53.2832 74.2739 53.275C74.2768 53.2669 74.2777 53.2582 74.2767 53.2497L70.5076 51.3727C70.39 51.3296 70.2651 51.3081 70.1394 51.3095C69.7928 51.3095 69.4895 51.4149 69.4895 52.2796C69.4895 53.102 69.8794 53.2918 70.7026 53.2918L74.2117 53.3129Z"
                fill="white"
              />
              <path
                d="M84.5654 47.1128H87.0132V49.8965H92.1902V47.1128H94.6379V54.8946H92.1902V51.9422H87.0132V54.8946H84.5654V47.1128Z"
                fill="white"
              />
              <path
                d="M96.1325 49.8542C96.1325 47.5766 97.4538 47.0283 99.6633 47.0283H103.584C105.793 47.0283 107.115 47.5555 107.115 49.8542V52.174C107.115 54.4516 105.793 55 103.584 55H99.6416C97.4322 55 96.1108 54.4727 96.1108 52.174L96.1325 49.8542ZM98.6452 51.4359C98.6452 52.5747 98.8835 52.9965 100.053 52.9965H103.194C104.364 52.9965 104.602 52.5747 104.602 51.4359V50.5924C104.602 49.6223 104.364 49.0318 103.064 49.0318H100.183C98.8835 49.0318 98.6452 49.6223 98.6452 50.5924V51.4359Z"
                fill="white"
              />
              <path
                d="M108.588 47.1128H115.281C117.621 47.1128 118.184 48.2516 118.184 49.77V50.4449C118.184 51.6047 117.664 52.9334 115.931 52.9334H111.057V54.8946H108.61V47.1128H108.588ZM111.036 51.0143H114.848C115.433 51.0143 115.649 50.6557 115.649 50.1918V49.8965C115.649 49.3693 115.39 49.0741 114.653 49.0741H111.057V51.0143H111.036Z"
                fill="white"
              />
              <path
                d="M119.483 47.1128H123.252L126.025 52.132L128.798 47.1128H132.567V54.8946H130.184V49.306H130.162L126.935 54.8946H125.094L121.888 49.306H121.866V54.8946H119.483V47.1128Z"
                fill="white"
              />
              <path
                d="M137.765 47.1128H140.95L145.347 54.8946H142.618L141.859 53.5238H136.899L136.184 54.8946H133.433L137.765 47.1128ZM137.809 51.8367H140.95L139.368 48.9054L137.809 51.8367Z"
                fill="white"
              />
              <path
                d="M146.235 47.1128H150.026L154.64 52.7646H154.662V47.1128H157.109V54.8946H153.319L148.705 49.2428H148.683V54.8946H146.235V47.1128Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_1260_771">
                <rect width="157.109" height="55" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </LosseLink>

        <HeaderDesktop />
        <HeaderMobile />
      </div>
    </nav>
  )
}
