import { useEffect, useRef } from "react";

export default function CookiebotDeclaration() {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) return () => { };

    const script = document.createElement('script');
    script.setAttribute('id', 'CookieDeclaration');
    script.setAttribute('src', 'https://consent.cookiebot.com/253d5b1a-5ff4-4a0c-9a95-76d1bf969dd4/cd.js');
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('async', 'true');
    ref.current?.append(script);

    return () => { }
  }, [])

  return <div ref={ref} />
}
