import { LossePlaatjie } from '@ubo/losse-sjedel'
import { EffectCube, Pagination } from 'swiper/modules'
import Content from '~/components/elements/Content'
import Slider, { Slide } from '~/components/elements/Slider'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentImageSlider({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentImageSlider" className="lg:section relative overflow-hidden">
      <div className="lg:container">
        <div className="flex flex-col gap-5 sm:gap-10">
          <Content className="content-md max-md:px-4 children-p:text-hh-blue children-p:text-base max-md:children-headings:!text-left max-md:children-p:!text-left md:children-p:text-2xl children-p:font-hh-heading">
            {fields.title}
          </Content>
          <div>
            <Slider
              modules={[Pagination, EffectCube]}
              // effect="cube"
              // cubeEffect={{
              //   shadow: false,
              //   slideShadows: false,
              //   shadowOffset: 20,
              //   shadowScale: 0.94
              // }}
              pagination={{
                clickable: true,
                el: '.content-image-slider-pagination',
                bulletClass: 'swiper-pagination-bullet-dark'
              }}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 0
                }
              }}
              className="max-lg:!-mx-10"
            >
              {fields?.items?.map((item, index) => (
                <Slide key={index} className="h-[462px] lg:h-[690px] !cursor-grab">
                  <LossePlaatjie
                    loading="eager"
                    maxwidth={2000}
                    className="h-[462px] lg:h-[690px] w-full object-cover"
                    src={item?.image}
                    alt="icon"
                  />
                </Slide>
              ))}
              <div className="flex justify-center py-6">
                <div className="content-image-slider-pagination"></div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </section>
  )
}
