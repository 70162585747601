import type { Dispatch, SetStateAction } from 'react'
import { createContext, useContext, useState } from 'react'
import type Swiper from 'swiper'
import { Autoplay, EffectCreative, Navigation, Pagination } from 'swiper/modules'
import Content from '~/components/elements/Content'
import Slider, { Slide } from '~/components/elements/Slider'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'
import useMediaQuery from '~/hooks/useMediaQuery'

const SectionContext = createContext<{
  fields: Page_Flexcontent_Flex_Content | null
  setContentSlider: Dispatch<SetStateAction<Swiper | undefined>>
  contentSlider: Swiper | undefined
  setVideoSlider: Dispatch<SetStateAction<Swiper | undefined>>
  videoSlider: Swiper | undefined
}>({
  fields: null,
  setContentSlider: (swi) => swi,
  contentSlider: undefined,
  setVideoSlider: (swi) => swi,
  videoSlider: undefined
})

export default function ContentVideoSlider({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const [contentSlider, setContentSlider] = useState<Swiper>()
  const [videoSlider, setVideoSlider] = useState<Swiper>()
  const isDesktop = useMediaQuery('(min-width: 1024px)')

  return (
    <SectionContext.Provider value={{ fields, setContentSlider, contentSlider, setVideoSlider, videoSlider }}>
      <section data-component="ContentVideoSlider" className="section bg-hh-mine-shaft text-white">
        <div className="container">
          {isDesktop && <Desktop />}
          {!isDesktop && <Mobile />}
        </div>
      </section>
    </SectionContext.Provider>
  )
}

function Mobile() {
  const { fields } = useContext(SectionContext)

  return (
    <>
      <div className="px-4">
        <Content className="children-headings:text-3xl children-headings:font-semibold children-p:mt-4 children-p:text-hh-blue">
          {fields?.description}
        </Content>
      </div>
      <div className="mt-5">
        <VideoSlider />
        <div className="mt-5">
          <ContentSlider />
          <div className="flex justify-center">
            <div className="content-video-slider-pagination !w-fit mt-6" />
          </div>
        </div>
      </div>
    </>
  )
}

function Desktop() {
  const { fields } = useContext(SectionContext)

  return (
    <div className="grid grid-cols-2 gap-x-24">
      <div className="col-span-1">
        <Content className="children-headings:text-5xl lg:mb-12 children-headings:font-semibold children-p:text-2xl children-p:mt-6 children-p:text-hh-blue">
          {fields?.description}
        </Content>
        <div className="pl-16 pt-7">
          <ContentSlider />
          <div className="content-video-slider-pagination mt-6" />
        </div>
      </div>
      <div className="col-span-1">
        <VideoSlider />
      </div>
    </div>
  )
}

function VideoSlider() {
  const { fields, contentSlider, setVideoSlider } = useContext(SectionContext)

  return (
    <Slider
      modules={[EffectCreative, Navigation]}
      onSwiper={(s) => setVideoSlider(s)}
      onSlideChange={(s) => {
        const videoElements: NodeListOf<HTMLVideoElement> | null = document.querySelectorAll('video')

        videoElements.forEach((edge) => {
          return edge.pause()
        })

        contentSlider?.slideTo(s.activeIndex)
      }}
      effect="creative"
      creativeEffect={{
        prev: {
          shadow: true,
          translate: [0, 0, -400]
        },
        next: {
          translate: ['100%', 0, 0]
        }
      }}
      className="max-lg:!-mx-4"
    >
      {fields?.items?.map((edge) => {
        return (
          <Slide key={edge?.title}>
            <div>
              {/* <video controls={true} width="100%" height="100%" playsInline className="mx-auto aspect-video min-w-full">
                <source src={edge?.videourl || ''} type="video/mp4" />
              </video> */}
              <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                <iframe
                  src={edge?.videourl || ''}
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                  title="Werken bij Heinen Hopman - Testimonial Julian"
                ></iframe>
              </div>
              <script src="https://player.vimeo.com/api/player.js"></script>
            </div>
          </Slide>
        )
      })}
    </Slider>
  )
}

function ContentSlider() {
  const { fields, setContentSlider, videoSlider } = useContext(SectionContext)

  return (
    <Slider
      onSwiper={(s) => setContentSlider(s)}
      onSlideChange={(s) => {
        const videoElements: NodeListOf<HTMLVideoElement> | null = document.querySelectorAll('video')

        videoElements.forEach((edge) => {
          return edge.pause()
        })
        videoSlider?.slideTo(s.activeIndex)
      }}
      modules={[Autoplay, Pagination, Navigation]}
      pagination={{ clickable: true, el: '.content-video-slider-pagination' }}
    >
      {fields?.items?.map((edge) => {
        return (
          <Slide key={edge?.title} className="[&_*]:font-hh-heading">
            <div>
              <span className="text-2xl font-semibold">{edge?.title}</span>
            </div>
            <div className="inline-flex items-center my-3">
              <svg className="h-6 w-auto mr-5" width="11" height="15" viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M5.30799 7.25637C4.60815 7.25637 3.92403 7.04884 3.34213 6.66003C2.76023 6.27122 2.3067 5.71859 2.03888 5.07202C1.77106 4.42545 1.70099 3.71398 1.83752 3.02759C1.97406 2.34119 2.31106 1.7107 2.80592 1.21584C3.30079 0.720974 3.93128 0.383968 4.61768 0.247435C5.30407 0.110903 6.01554 0.180977 6.66211 0.448794C7.30867 0.716612 7.86131 1.17015 8.25012 1.75204C8.63893 2.33394 8.84646 3.01806 8.84646 3.71791C8.84552 4.65608 8.47242 5.55556 7.80903 6.21894C7.14564 6.88233 6.24617 7.25543 5.30799 7.25637ZM5.30799 1.35893C4.84143 1.35893 4.38535 1.49728 3.99742 1.75649C3.60949 2.0157 3.30713 2.38412 3.12859 2.81517C2.95004 3.24621 2.90333 3.72052 2.99435 4.17812C3.08537 4.63572 3.31004 5.05605 3.63995 5.38595C3.96986 5.71586 4.39019 5.94053 4.84778 6.03155C5.30538 6.12258 5.77969 6.07586 6.21074 5.89732C6.64178 5.71877 7.0102 5.41641 7.26941 5.02848C7.52862 4.64055 7.66697 4.18447 7.66697 3.71791C7.66697 3.09227 7.41844 2.49225 6.97604 2.04986C6.53365 1.60747 5.93363 1.35893 5.30799 1.35893Z"
                  fill="#00A1FF"
                />
                <path
                  d="M10.6154 14.3331H9.4359V11.359C9.43527 10.8967 9.25134 10.4535 8.92444 10.1266C8.59754 9.79971 8.15434 9.61578 7.69203 9.61515H2.92336C2.46105 9.61578 2.01785 9.79971 1.69094 10.1266C1.36404 10.4535 1.18011 10.8967 1.17949 11.359V14.3331H0V11.359C0.000936411 10.584 0.309233 9.84096 0.857266 9.29293C1.4053 8.7449 2.14832 8.43661 2.92336 8.43567H7.69203C8.46706 8.43661 9.21009 8.7449 9.75812 9.29293C10.3062 9.84096 10.6144 10.584 10.6154 11.359V14.3331Z"
                  fill="#00A1FF"
                />
              </svg>
              <span className="text-xl">{edge?.subtitle}</span>
            </div>
            <Content className="text-hh-concrete opacity-50 children-p:text-xl">{edge?.description}</Content>
          </Slide>
        )
      })}
    </Slider>
  )
}
