import { useLocation } from '@remix-run/react'
import { useEffect, useState } from 'react'

export function useUtmData(): [boolean, { medium: string; source: string; content: string }] {
  const [utmData, setUtmData] = useState({
    medium: '',
    source: '',
    content: ''
  })
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const sessionUtm = sessionStorage.getItem('utm_data')
    let retry = true

    if (sessionUtm) {
      const sessionUtmData = JSON.parse(sessionUtm)

      if (new Date().getTime() >= sessionUtmData.expires) {
        sessionStorage.removeItem('utm_data')
      } else {
        setUtmData({
          medium: sessionUtmData.medium,
          source: sessionUtmData.source,
          content: sessionUtmData.content
        })

        retry = false
      }
    }

    if (retry && !utmData.source) {
      const searchParams = new URLSearchParams(location.search)
      const utm_medium = searchParams.get('utm_medium') || ''
      const utm_source = searchParams.get('utm_source') || ''
      const utm_content = searchParams.get('utm_content') || ''

      setUtmData({
        medium: utm_medium,
        source: utm_source,
        content: utm_content
      })
    }

    setLoaded(true)
  }, [])

  return [loaded, utmData]
}

function setUtmSession(utm_source: string, utm_medium: string, utm_content: string) {
  sessionStorage.setItem(
    'utm_data',
    JSON.stringify({
      expires: new Date().getTime() + 5 * 60000,
      medium: utm_medium,
      source: utm_source,
      content: utm_content
    })
  )
}

export function useUtm() {
  const location = useLocation()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const utm_source = searchParams.get('utm_source') || '' // example: Google, Facebook etc
    const utm_medium = searchParams.get('utm_medium') || '' // example: cpc, organic, email etc
    const utm_content = searchParams.get('utm_content') || ''
    const gad_source = searchParams.get('gad_source') || '' // paid

    if (utm_source || utm_medium) {
      setUtmSession(utm_source, utm_medium, utm_content)
    } else if (!!document.referrer && !!window.location.href) {
      const referrer = new URL(document.referrer)
      const originHost = new URL(window.location.href).host.toLowerCase()
      const referrerHost = referrer.host.toLowerCase()

      if (referrerHost !== originHost) {
        if (gad_source) {
          setUtmSession('Google', 'paid', '')
        } else if (referrerHost.includes('google')) {
          setUtmSession('Google', 'organic', '')
        } else if (referrerHost.includes('facebook')) {
          setUtmSession('Facebook', 'organic', '')
        } else if (referrerHost.includes('linkedin') || referrerHost.includes('lnkd')) {
          setUtmSession('LinkedIn', 'organic', '')
        } else if (referrerHost.includes('bing')) {
          setUtmSession('Bing', 'organic', '')
        } else {
          setUtmSession(referrerHost, 'organic', '')
        }
      } else {
        setUtmSession('Eigen website', 'organic', '')
      }
    } else {
      setUtmSession('Eigen website', 'organic', '')
    }
  }, [location])
}
