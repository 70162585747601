import type { Page_Flexcontent_Flex_Banner, Vacature } from '~/graphql/types'
import type { LoaderData } from '@ubo/losse-sjedel'
import { LosseLink, LossePlaatjie, useLoaderData } from '@ubo/losse-sjedel'
import { createContext, useContext, useState, useEffect, useRef } from 'react'
import Content from '~/components/elements/Content'
import clsx from 'clsx'
import { useInView } from 'framer-motion'
import useInfo from '~/hooks/useInfo'

const SectionContext = createContext<{
  fields: Page_Flexcontent_Flex_Banner | null
  page: Vacature | null
}>({
  fields: null,
  page: null
})

export default function BannerVacancy({ fields }: { fields: Page_Flexcontent_Flex_Banner }) {
  const { page } = useLoaderData<LoaderData<Vacature>>()

  return (
    // @ts-ignore
    <SectionContext.Provider value={{ fields, page }}>
      <section data-component="BannerVacancy" className="container-lg max-lg:px-0 max-lg:pt-0">
        <div className="lg:hidden">
          <Mobile />
        </div>
        <div className="hidden lg:block">
          <Desktop />
        </div>
      </section>
    </SectionContext.Provider>
  )
}

function Mobile() {
  const { page } = useContext(SectionContext)
  const info = useInfo()

  const bannerRef = useRef(null)
  const bannerInView = useInView(bannerRef, { margin: '-100px' })
  const [isSticky, setIsSticky] = useState(false)

  useEffect(() => {
    setIsSticky(!bannerInView)
  }, [bannerInView])

  return (
    <>
      <div className="relative">
        <div ref={bannerRef}>
          <LossePlaatjie
            maxwidth={2000}
            src={page?.featuredImage?.node ?? info.defaultVacancyImage}
            className="min-h-[450px] object-cover w-[170%] -left-[70%] relative max-w-[170%]"
          />
        </div>

        <div className="absolute bottom-0 h-1/2 w-full bg-gradient-to-t from-black"></div>
        <div className="absolute bottom-0 w-full">
          <div className="container">
            <h1 className="relative mb-3 text-2xl text-white uppercase font-semibold">{page?.title}</h1>
            <Salary />
            <Details />
            <div className="flex justify-center my-7">
              <Anchor />
            </div>
          </div>
        </div>
      </div>
      <div className="container py-7">
        <Intro />
      </div>

      <div
        className={clsx(
          isSticky ? 'opacity-100' : 'opacity-0',
          'fixed w-full bottom-0 left-0 bg-hh-mine-shaft py-2  z-[99] transition-opacity'
        )}
      >
        <div className="container flex justify-center sm:justify-between items-center">
          <Content className="text-white content hidden sm:block">
            <h2>SOLLICITEREN?</h2>
          </Content>
          <div
            onClick={() =>
              setTimeout(() => {
                setIsSticky(false)
              }, 1000)
            }
            id="sticky-anchor"
          >
            <Anchor />
          </div>
        </div>
      </div>
    </>
  )
}

function Desktop() {
  const { page } = useContext(SectionContext)
  const [isSticky, setIsSticky] = useState(false)

  const info = useInfo()

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.pageYOffset >= 750)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <>
      <div className="relative lg:pt-20">
        <div className="w-full overflow-hidden relative">
          <div className="absolute bottom-0 h-1/2 w-full bg-gradient-to-t from-black"></div>
          <LossePlaatjie
            maxwidth={2000}
            loading="eager"
            placeholder="blur"
            className="w-full min-h-[500px] h-[800px] object-cover"
            src={page?.featuredImage?.node ?? info.defaultVacancyImage}
          />
          <div className="relative flex w-full justify-center">
            <div className="absolute bottom-12 pt-5">
              <div className="container">
                <h1 className="relative mb-6 text-5xl text-white uppercase font-semibold">{page?.title}</h1>
              </div>
              <div className="container grid grid-cols-12">
                <div className="relative col-span-7">
                  <div className="flex items-center">
                    <div className="mr-5">
                      <Salary />
                    </div>
                    <Details />
                  </div>
                  <div className="mt-6">
                    <Intro />
                  </div>
                </div>
                <div className={clsx(isSticky && 'hidden', 'col-span-5')}>
                  <div
                    onClick={() =>
                      setTimeout(() => {
                        setIsSticky(false)
                      }, 1000)
                    }
                    className="-mt-2 relative flex justify-end mr-12"
                  >
                    <Anchor />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={clsx(
          isSticky ? 'opacity-100' : 'opacity-0',
          'fixed bottom-0 left-0 flex justfiy-end py-5 z-[99] w-full bg-hh-mine-shaft text-white transition-opacity'
        )}
      >
        <div className="container flex justify-between items-center">
          <Content className="content">
            <h2>SOLLICITEREN?</h2>
          </Content>

          <div
            onClick={() =>
              setTimeout(() => {
                setIsSticky(false)
              }, 1000)
            }
            id="sticky-anchor"
            className="ml-auto"
          >
            <Anchor />
          </div>
        </div>
      </div>
    </>
  )
}

function Details() {
  const { page } = useContext(SectionContext)

  const educationLevel = page?.categories?.edges.filter((edge) => edge.node.parentDatabaseId && edge.node.parentDatabaseId === 6)![0]?.node
    ?.name

  return (
    <ul className="max-lg:mt-5 flex flex-wrap gap-x-4 [&_li]:inline-flex [&_li]:items-center [&_li]:mr-4 [&_li]:text-sm md:[&_li]:text-base [&_svg]:mr-3">
      {page?.recap?.location && (
        <li>
          <svg width="12" height="17" viewBox="0 0 12 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5.56215 16.7782C5.65453 16.9168 5.81005 17 5.97656 17C6.14308 17 6.2986 16.9168 6.39097 16.7782C7.56965 15.0102 9.30574 12.8268 10.5155 10.6061C11.4829 8.83054 11.9531 7.31618 11.9531 5.97656C11.9531 2.68109 9.27204 0 5.97656 0C2.68109 0 0 2.68109 0 5.97656C0 7.31618 0.470256 8.83054 1.4376 10.6061C2.64649 12.8251 4.3859 15.0139 5.56215 16.7782ZM5.97656 0.996094C8.72279 0.996094 10.957 3.23033 10.957 5.97656C10.957 7.14545 10.5265 8.50392 9.64083 10.1296C8.59802 12.0437 7.10992 13.9854 5.97656 15.6177C4.84337 13.9856 3.35518 12.0438 2.3123 10.1296C1.42664 8.50392 0.996094 7.14545 0.996094 5.97656C0.996094 3.23033 3.23033 0.996094 5.97656 0.996094Z"
              fill="#00A1FF"
            />
            <path
              d="M5.97656 8.96484C7.6243 8.96484 8.96484 7.6243 8.96484 5.97656C8.96484 4.32882 7.6243 2.98828 5.97656 2.98828C4.32882 2.98828 2.98828 4.32882 2.98828 5.97656C2.98828 7.6243 4.32882 8.96484 5.97656 8.96484ZM5.97656 3.98438C7.07505 3.98438 7.96875 4.87807 7.96875 5.97656C7.96875 7.07505 7.07505 7.96875 5.97656 7.96875C4.87807 7.96875 3.98438 7.07505 3.98438 5.97656C3.98438 4.87807 4.87807 3.98438 5.97656 3.98438Z"
              fill="#00A1FF"
            />
          </svg>
          <span className="text-white font-hh-heading">{page?.recap?.location}</span>
        </li>
      )}

      {page?.recap?.hours && (
        <li>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M7.99971 0C3.58899 0 0 3.5887 0 8C0 12.4113 3.58899 16 7.99971 16C12.4113 16 15.9997 12.4113 15.9997 8C15.9997 3.5887 12.4113 0 7.99971 0ZM7.99971 14.9334C4.17676 14.9334 1.06661 11.8232 1.06661 8C1.06661 4.17676 4.17705 1.06661 7.99971 1.06661C11.8232 1.06661 14.9331 4.17676 14.9331 8C14.9331 11.8232 11.8235 14.9334 7.99971 14.9334Z"
              fill="#00A1FF"
            />
            <path
              d="M11.9619 7.42084H8.53332V2.88739C8.53332 2.59279 8.29448 2.35394 8.00016 2.35394C7.70613 2.35394 7.46729 2.59307 7.46729 2.88739V8.48716H11.9619C12.2562 8.48716 12.4951 8.2486 12.4948 7.954C12.4948 7.65968 12.2557 7.42084 11.9619 7.42084Z"
              fill="#00A1FF"
            />
          </svg>
          <span className="text-white font-hh-heading">{page?.recap?.hours}</span>
        </li>
      )}

      {educationLevel && (
        <li>
          <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.502 0H0.498047C0.222992 0 0 0.222992 0 0.498047V13.8346C0 14.1097 0.222992 14.3327 0.498047 14.3327H10.1358V16.502C10.1358 16.6746 10.2252 16.8349 10.372 16.9256C10.5189 17.0163 10.7021 17.0246 10.8566 16.9474L12.2343 16.2586L13.6119 16.9474C13.7663 17.0246 13.9497 17.0164 14.0965 16.9256C14.2433 16.8349 14.3327 16.6746 14.3327 16.502V14.3327H16.502C16.777 14.3327 17 14.1097 17 13.8346V0.498047C17 0.222992 16.777 0 16.502 0ZM12.2343 12.2697C11.3323 12.2697 10.5984 11.5358 10.5984 10.6338C10.5984 9.73184 11.3323 8.99805 12.2343 8.99805C13.1363 8.99805 13.8701 9.73187 13.8701 10.6339C13.8701 11.5359 13.1363 12.2697 12.2343 12.2697ZM13.3366 15.6961L12.457 15.2563C12.3168 15.1862 12.1517 15.1862 12.0115 15.2563L11.1319 15.6961V13.0229C11.4675 13.1784 11.8408 13.2658 12.2343 13.2658C12.6277 13.2658 13.001 13.1784 13.3366 13.0229V15.6961ZM16.0039 13.3366H14.3327V12.2202C14.6672 11.7787 14.8661 11.2292 14.8661 10.6339C14.8661 9.18262 13.6855 8.00195 12.2342 8.00195C10.783 8.00195 9.60234 9.18262 9.60234 10.6339C9.60234 11.2292 9.80126 11.7787 10.1358 12.2202V13.3366H0.996094V0.996094H16.0039V13.3366Z"
              fill="#00A1FF"
            />
            <path
              d="M5.29932 4.1969H11.7009C11.9759 4.1969 12.1989 3.97391 12.1989 3.69885C12.1989 3.4238 11.9759 3.20081 11.7009 3.20081H5.29932C5.02426 3.20081 4.80127 3.4238 4.80127 3.69885C4.80127 3.97391 5.02423 4.1969 5.29932 4.1969Z"
              fill="#00A1FF"
            />
            <path
              d="M3.69873 6.8642H13.3011C13.5761 6.8642 13.7991 6.6412 13.7991 6.36615C13.7991 6.0911 13.5761 5.8681 13.3011 5.8681H3.69873C3.42368 5.8681 3.20068 6.0911 3.20068 6.36615C3.20068 6.6412 3.42368 6.8642 3.69873 6.8642Z"
              fill="#00A1FF"
            />
            <path
              d="M3.69873 8.99805H7.96643C8.24148 8.99805 8.46447 8.77505 8.46447 8.5C8.46447 8.22495 8.24148 8.00195 7.96643 8.00195H3.69873C3.42368 8.00195 3.20068 8.22495 3.20068 8.5C3.20068 8.77505 3.42368 8.99805 3.69873 8.99805Z"
              fill="#00A1FF"
            />
            <path
              d="M3.69873 11.1319H7.96643C8.24148 11.1319 8.46447 10.9089 8.46447 10.6339C8.46447 10.3588 8.24148 10.1358 7.96643 10.1358H3.69873C3.42368 10.1358 3.20068 10.3588 3.20068 10.6339C3.20068 10.9089 3.42368 11.1319 3.69873 11.1319Z"
              fill="#00A1FF"
            />
          </svg>
          <span className="text-white font-hh-heading">{educationLevel}</span>
        </li>
      )}
    </ul>
  )
}

function Intro() {
  const { page } = useContext(SectionContext)

  if (!page?.recap?.excerpt) return null

  return <Content className="lg:text-white">{page?.recap?.excerpt}</Content>
}

function Salary() {
  const { page } = useContext(SectionContext)

  if (!page?.recap?.salary) return null

  return (
    <div className="bg-hh-blue rounded-full px-2 py-[1px] text-white w-fit">
      <span className="font-hh-heading">{page?.recap?.salary}</span>
    </div>
  )
}

function Anchor() {
  return (
    <LosseLink to="#solliciteren" className="button-green min-w-[217px]">
      SOLLICITEER
    </LosseLink>
  )
}
