import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import type { Category, Maybe, Page_Flexcontent_Flex_Vacancies } from '~/graphql/types'

interface Filter extends Category {
  defaultVacatures: {
    pageInfo: {
      total: number
    }
  }
  bblVacatures: {
    pageInfo: {
      total: number
    }
  }
  stageVacatures: {
    pageInfo: {
      total: number
    }
  }
}

export default function VacanciesCategoryPreview({ fields }: { fields: Page_Flexcontent_Flex_Vacancies }) {
  return (
    <section data-component="VacanciesCategoryPreview" className="section">
      <div className="container">
        <div className="flex flex-col gap-7 md:gap-10 lg:gap-20">
          <Content className="content md:children-h2:!text-center children-h2:!text-left md:children-p:!text-center children-p:!text-left children-p:text-base sm:children-p:text-xl lg:children-p:text-3xl children-p:font-hh-heading children-p:text-hh-blue">
            {fields.description}
          </Content>
          <div className="grid grid-cols-2 gap-2 md:gap-8 lg:gap-16 max-sm:-mx-4">
            {fields.filters?.map((filter, index) => {
              const _filter = filter as Filter

              return (
                <LosseLink
                  to={`/vacatures/?_f=${_filter?.databaseId}`}
                  className="group/block aspect-square relative overflow-hidden block"
                  key={index}
                >
                  <LossePlaatjie
                    maxwidth={1000}
                    src={_filter?.recap?.image}
                    alt="sector"
                    className="group-hover/block:scale-105 transition-transform absolute z-10 h-full w-full object-cover top-0 left-0"
                  />
                  <div className="absolute z-20 h-full w-full left-0 bottom-0 [background:linear-gradient(180deg,rgba(50,50,50,0)_0%,black_100%)]" />
                  <div className="absolute bottom-0 left-0 z-30 p-5 md:p-10">
                    <h3 className="font-hh-heading font-bold uppercase text-lg sm:text-xl md:text-4xl md:mb-2 text-white">
                      {_filter?.name}
                    </h3>
                    {_filter?.defaultVacatures?.pageInfo.total > 0 && (
                      <LosseLink
                        to={`/vacatures/?_f=${_filter?.databaseId}`}
                        className="group text-base sm:text-lg md:text-xl font-hh-heading text-hh-blue flex items-center gap-3 hover:underline"
                      >
                        {_filter?.defaultVacatures?.pageInfo.total} {_filter?.vacatures?.pageInfo.total === 1 ? 'Vacature' : 'Vacatures'}
                        <svg
                          className="mt-1 group-hover:translate-x-1 transition-transform"
                          width="10"
                          height="16"
                          viewBox="0 0 10 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.61529 15.8298L0.96875 14.1797L7.14065 8.00317L0.96875 1.82659L2.61996 0.176544L8.78719 6.35312C9.22472 6.79079 9.47052 7.38431 9.47052 8.00317C9.47052 8.62203 9.22472 9.21555 8.78719 9.65321L2.61529 15.8298Z"
                            fill="white"
                          />
                        </svg>
                      </LosseLink>
                    )}

                    {_filter?.bblVacatures?.pageInfo.total > 0 && (
                      <LosseLink
                        to={`/bbl/?_f=${_filter?.databaseId}`}
                        className="group text-base sm:text-lg md:text-xl font-hh-heading text-hh-blue flex items-center gap-3 hover:underline"
                      >
                        {_filter?.bblVacatures?.pageInfo.total}{' '}
                        {_filter?.bblVacatures?.pageInfo.total === 1 ? 'BBL vacature' : 'BBL vacatures'}
                        <svg
                          className="mt-1 group-hover:translate-x-1 transition-transform"
                          width="10"
                          height="16"
                          viewBox="0 0 10 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.61529 15.8298L0.96875 14.1797L7.14065 8.00317L0.96875 1.82659L2.61996 0.176544L8.78719 6.35312C9.22472 6.79079 9.47052 7.38431 9.47052 8.00317C9.47052 8.62203 9.22472 9.21555 8.78719 9.65321L2.61529 15.8298Z"
                            fill="white"
                          />
                        </svg>
                      </LosseLink>
                    )}

                    {_filter?.stageVacatures?.pageInfo.total > 0 && (
                      <LosseLink
                        to={`/stages/?_f=${filter?.databaseId}`}
                        className="group sm:text-lg md:text-xl font-hh-heading text-hh-blue flex items-center gap-3 hover:underline"
                      >
                        {_filter?.stageVacatures?.pageInfo.total} {_filter?.stageVacatures?.pageInfo.total === 1 ? 'Stage' : 'Stages'}
                        <svg
                          className="mt-1 group-hover:translate-x-1 transition-transform"
                          width="10"
                          height="16"
                          viewBox="0 0 10 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.61529 15.8298L0.96875 14.1797L7.14065 8.00317L0.96875 1.82659L2.61996 0.176544L8.78719 6.35312C9.22472 6.79079 9.47052 7.38431 9.47052 8.00317C9.47052 8.62203 9.22472 9.21555 8.78719 9.65321L2.61529 15.8298Z"
                            fill="white"
                          />
                        </svg>
                      </LosseLink>
                    )}
                  </div>
                </LosseLink>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}
