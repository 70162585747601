import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import Slider, { Slide } from '~/components/elements/Slider'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'
import useInfo from '~/hooks/useInfo'
import { replaceInfoVariables } from '~/services/utils'

export default function ContentSliderBlocks({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  const info = useInfo()

  return (
    <section data-component="ContentSliderBlocks" className="section relative overflow-hidden">
      <div className="container flex flex-col gap-8 md:gap-10 xl:gap-20">
        <Content className="children-headings:text-3xl lg:children-headings:text-5xl children-headings:font-semibold children-p:text-hh-blue">
          {fields.description}
        </Content>
        <div>
          <Slider
            freeMode
            breakpoints={{
              0: {
                slidesPerView: 2.1,
                spaceBetween: 10
              },
              576: {
                slidesPerView: 3.1,
                spaceBetween: 15
              },
              1024: {
                slidesPerView: 4.1,
                spaceBetween: 20
              },
              1279: {
                slidesPerView: 6,
                spaceBetween: 20
              }
            }}
            className="!overflow-visible"
          >
            {fields?.items?.map((item, index) => (
              <Slide key={index} className="">
                <div className="flex flex-col px-4 py-5 justify-between aspect-square bg-hh-blue/20">
                  <div className="flex justify-start">
                    <LossePlaatjie maxwidth={100} src={item?.icon} alt="icon" />
                  </div>
                  <Content className="children-p:font-hh-heading children-p:font-bold md:children-p:text-lg children-p:!leading-[1]">
                    {item?.description}
                  </Content>
                </div>
              </Slide>
            ))}
          </Slider>
        </div>
        {fields.links && (
          <div className="lg:hidden flex justify-center items-center gap-2 my-4">
            {fields.links.map((link, index) => (
              <Button className={clsx(index >= 1 && 'button-blue', 'button-blue')} key={index} to={link?.link?.url || '/'}>
                {replaceInfoVariables(link?.link?.title || '', info)}
              </Button>
            ))}
          </div>
        )}
      </div>
    </section>
  )
}
