import { useLoaderData } from '@ubo/losse-sjedel'
import { useEffect, useState } from 'react'
import Preview from '~/components/elements/Vacancies/Preview'
import type { Page_Flexcontent_Flex_Vacancies, Vacature } from '~/graphql/types'

export default function VacanciesRelated({ fields }: { fields: Page_Flexcontent_Flex_Vacancies }) {
  const { page } = useLoaderData<{ page: Vacature }>()
  const [vacancies, setVacancies] = useState(fields.vacancies?.edges)

  useEffect(() => {
    const related = fields.vacancies?.edges.filter((r) => {
      const vacancy = r.node as Vacature
      // only show vacancies that have the same 'type' category as the current page
      return vacancy.categories?.edges.some(
        (c) => c.node.name === page.categories?.edges.find((f) => f.node.parentDatabaseId === 5)?.node.name
      )
    })

    const unrelated = fields.vacancies?.edges.filter((r) => {
      // remove all entries that are in related
      return !related?.some((f) => f.node.databaseId === r.node.databaseId)
    })

    // Combine the two arrays
    let combined: any[] = []
    combined = combined.concat(related, unrelated)

    setVacancies(combined)
  }, [fields.vacancies?.edges, page.categories?.edges])

  return (
    <section data-component="VacanciesRelated" className="section">
      <div className="container">
        <div className="grid lg:py-10 grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-16">
          {vacancies
            ?.filter((f) => f.node.databaseId !== page.databaseId)
            .slice(0, 2)
            .map((v, index) => {
              const vacancy = v.node as Vacature
              return <Preview key={index} fields={vacancy} />
            })}
        </div>
      </div>
    </section>
  )
}
