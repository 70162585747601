import { motion } from 'framer-motion'
import type { Maybe, Component_Header_MenuHeader_Submenu } from '~/graphql/types'
import { LosseLink } from '@ubo/losse-sjedel'

export default function HeaderSubmenu({ fields }: { fields: Maybe<Component_Header_MenuHeader_Submenu>[] }) {
  return (
    <motion.ul
      className="relative w-full overflow-hidden lg:absolute"
      initial={{ height: 0 }}
      animate={{ height: 'fit-content' }}
      exit={{ height: 0 }}
    >
      <div className="lg:pt-3">
        <div className="py-1 pr-2 pl-3 lg:rounded-md lg:bg-white lg:p-4 lg:shadow-2xl">
          {fields.map((f, index: number) => (
            <li className="list-none hover:opacity-70" key={index}>
              <LosseLink className="text-site-base" to={f?.link?.url || '/'}>
                {f?.link?.title}
              </LosseLink>
            </li>
          ))}
        </div>
      </div>
    </motion.ul>
  )
}
