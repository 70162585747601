import { LosseLink, LossePlaatjie, useLoaderData } from '@ubo/losse-sjedel'
import { type LoaderData } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import type { Component_Footer, Page } from '~/graphql/types'
import useInfo from '~/hooks/useInfo'
import Content from '../elements/Content'

export interface FooterLoaderData extends Omit<LoaderData, 'footer'> {
  footer: Component_Footer
}

export interface ExtraFooterLoaderData extends Omit<FooterLoaderData, 'page'> {
  page: Page
}

export default function Footer() {
  const { footer, page } = useLoaderData<ExtraFooterLoaderData>()
  const info = useInfo()

  return (
    <>
      {page.pagesettings?.extrafooterblock && (
        <section className="container mt-10">
          <div className="bg-hh-blue relative p-8 md:px-16 xl:px-32 md:py-10 xl:py-14 mb-[38px] lg:-mb-16 grid grid-cols-1 lg:grid-cols-5 gap-8 xl:gap-10">
            <Content className="lg:col-span-3 content md:content-lg children-p:text-sm md:children-p:text-2xl xl:children-p:text-3xl children-p:font-hh-heading children-p:font-light children-p:text-hh-mine-shaft children-headings:text-white">
              {footer.extrablock?.description}
            </Content>
            <div className="lg:col-span-2 flex justify-center items-center gap-4">
              {footer.extrablock?.links?.map((link, index) => (
                <LosseLink className="button-mine-shaft-opaque md:w-[unset] w-full" src={link?.link} key={index} />
              ))}
            </div>
          </div>
        </section>
      )}
      <footer className={clsx(page.pagesettings?.extrafooterblock && 'lg:pt-16', 'bg-black pb-12')}>
        <div className="container">
          <div className="grid grid-cols-2 lg:grid-cols-4 text-white">
            <div className="col-span-2 flex justify-between relative lg:col-span-1">
              <div className="flex flex-col gap-7 lg:gap-12 lg:pt-20 pt-8">
                <div>
                  <h2 className="font-hh-heading mb-3 uppercase text-xl font-bold">Contact</h2>
                  <div>
                    <a href={`mailto:${info.email}`} target="_blank" rel="noreferrer" className="flex group items-center gap-1">
                      <div className="text-hh-blue w-4 font-hh-heading font-bold">M</div>
                      <div className="font-hh-heading font-light group-hover:underline">{info.email}</div>
                    </a>
                    <a href={`tel:${info.phonenumber}`} target="_blank" rel="noreferrer" className="flex group items-center gap-1">
                      <div className="text-hh-blue w-4 font-hh-heading font-bold">T</div>
                      <div className="font-hh-heading font-light group-hover:underline">{info.phonenumber}</div>
                    </a>
                  </div>
                </div>
                <div>
                  <h2 className="font-hh-heading mb-2 lg:mb-3 uppercase text-xl font-bold">Hoofdkantoor</h2>
                  <div className="font-hh-heading">
                    <div>{info.street}</div>
                    <div>{info.zipcode}</div>
                    <div>{info.city}</div>
                  </div>
                </div>
                <div>
                  <h2 className="font-hh-heading mb-3 uppercase text-base lg:text-xl font-bold">Follow us</h2>
                  <div className="flex items-center gap-2">
                    <a className="group" target="_blank" rel="noreferrer" href={info.twitterUrl || '/'}>
                      <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_1371_1960)">
                          <path
                            d="M13.5 0C6.05613 0 0 6.05613 0 13.5C0 20.9433 6.05613 27 13.5 27C20.9433 27 27 20.9433 27 13.5C27 6.05613 20.9444 0 13.5 0ZM19.5224 10.4102C19.5284 10.544 19.5317 10.6788 19.5317 10.8137C19.5317 14.9198 16.4071 19.6529 10.6903 19.6529C8.93547 19.6529 7.30194 19.1401 5.92725 18.2581C6.17032 18.2869 6.41775 18.3016 6.66843 18.3016C8.12469 18.3016 9.46403 17.8046 10.5277 16.9715C9.16821 16.9465 8.02028 16.0482 7.62495 14.8132C7.81419 14.8491 8.0094 14.8692 8.20897 14.8692C8.49228 14.8692 8.76744 14.8323 9.02791 14.761C7.60646 14.4761 6.53575 13.2205 6.53575 11.7142C6.53575 11.7012 6.53575 11.6876 6.53629 11.6751C6.95501 11.9073 7.43408 12.0475 7.94306 12.0633C7.10998 11.507 6.56131 10.5554 6.56131 9.47762C6.56131 8.90774 6.71411 8.37374 6.98165 7.91479C8.51349 9.79519 10.8039 11.0318 13.3858 11.1623C13.3325 10.9344 13.3059 10.6979 13.3059 10.4537C13.3059 8.73808 14.6969 7.34653 16.4125 7.34653C17.3065 7.34653 18.1129 7.72392 18.6806 8.32752C19.3892 8.18831 20.0526 7.93056 20.6546 7.57329C20.4207 8.29924 19.9297 8.90774 19.2864 9.29274C19.9156 9.21769 20.5159 9.0513 21.0711 8.80387C20.6567 9.42596 20.1298 9.97355 19.5224 10.4102Z"
                            className="fill-hh-mine-shaft group-hover:fill-hh-blue smooth"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1371_1960">
                            <rect width="27" height="27" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </a>
                    <a className="group" target="_blank" rel="noreferrer" href={info.facebookUrl || '/'}>
                      <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_1392_1805)">
                          <path
                            d="M13.5 0C10.83 0 8.21988 0.791761 5.99981 2.27516C3.77975 3.75856 2.04942 5.86697 1.02763 8.33377C0.00584931 10.8006 -0.261496 13.515 0.259405 16.1337C0.780306 18.7525 2.06606 21.1579 3.95407 23.0459C5.84208 24.934 8.24755 26.2197 10.8663 26.7406C13.485 27.2615 16.1994 26.9942 18.6662 25.9724C21.133 24.9506 23.2415 23.2203 24.7249 21.0002C26.2083 18.7801 27 16.17 27 13.5C27 9.91958 25.5777 6.4858 23.046 3.95406C20.5142 1.42232 17.0804 0 13.5 0V0ZM17.3404 8.78566C17.3404 9.03967 17.2338 9.14092 16.9851 9.14092C16.5073 9.14092 16.0295 9.14092 15.5534 9.16046C15.0774 9.18 14.818 9.39671 14.818 9.89408C14.8074 10.427 14.818 10.9492 14.818 11.4928H16.8626C17.1539 11.4928 17.2534 11.5922 17.2534 11.8853C17.2534 12.5959 17.2534 13.3099 17.2534 14.0276C17.2534 14.3171 17.161 14.4077 16.8679 14.4095H14.8003V20.1896C14.8003 20.4987 14.7044 20.5964 14.3988 20.5964H12.1749C11.9067 20.5964 11.8019 20.4916 11.8019 20.2234V14.4184H10.0362C9.75909 14.4184 9.65962 14.3171 9.65962 14.0382C9.65962 13.3194 9.65962 12.6012 9.65962 11.8836C9.65962 11.6064 9.76442 11.5016 10.038 11.5016H11.8019V9.94737C11.7809 9.2492 11.9484 8.55829 12.2868 7.94724C12.6385 7.33034 13.2022 6.86201 13.873 6.62921C14.3084 6.47079 14.7686 6.39139 15.2319 6.39474H16.978C17.2285 6.39474 17.3333 6.50487 17.3333 6.75C17.3422 7.43388 17.3422 8.11066 17.3404 8.78566Z"
                            className="fill-hh-mine-shaft group-hover:fill-hh-blue smooth"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1392_1805">
                            <rect width="27" height="27" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </a>
                    <a className="group" target="_blank" rel="noreferrer" href={info.linkedinUrl || '/'}>
                      <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_1392_1806)">
                          <path
                            d="M13.5 0C6.0453 0 0 6.0453 0 13.5C0 20.9547 6.0453 27 13.5 27C20.9547 27 27 20.9547 27 13.5C27 6.0453 20.9547 0 13.5 0ZM9.57706 20.4082H6.28919V10.5166H9.57706V20.4082ZM7.93323 9.16589H7.9118C6.8085 9.16589 6.09494 8.40639 6.09494 7.45718C6.09494 6.48653 6.83034 5.74805 7.95506 5.74805C9.07979 5.74805 9.77193 6.48653 9.79335 7.45718C9.79335 8.40639 9.07979 9.16589 7.93323 9.16589ZM21.4324 20.4082H18.145V15.1164C18.145 13.7865 17.6689 12.8795 16.4793 12.8795C15.5711 12.8795 15.0301 13.4913 14.7924 14.0819C14.7055 14.2933 14.6843 14.5887 14.6843 14.8843V20.4082H11.3966C11.3966 20.4082 11.4397 11.4446 11.3966 10.5166H14.6843V11.9171C15.1212 11.2431 15.9029 10.2844 17.6473 10.2844C19.8104 10.2844 21.4324 11.6982 21.4324 14.7364V20.4082Z"
                            className="fill-hh-mine-shaft group-hover:fill-hh-blue smooth"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1392_1806">
                            <rect width="27" height="27" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
              <LossePlaatjie className="lg:hidden block mr-4 w-[90px]" maxwidth={200} src={footer.contactFooter?.label} alt="label" />
            </div>
            <div className="col-span-1 pt-12 lg:pt-20 flex">
              <div>
                <h2 className="font-hh-heading mb-3 uppercase text-base lg:text-xl font-bold">Menu</h2>
                <nav>
                  <ul className="flex flex-col gap-2">
                    {footer.menuFooter?.map((item, index) => (
                      <li key={index}>
                        <LosseLink
                          className="font-hh-heading font-normal text-base hover:text-white smooth text-hh-grey"
                          src={item?.link}
                        />
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>
            </div>
            <div className="col-span-1 pt-12 lg:pt-20 flex flex-col justify-between">
              <div>
                <LossePlaatjie className="w-[100px] lg:w-[135px]" maxwidth={135} src={footer.glassdoor} alt="glassdoor" />
              </div>
              <div className="lg:hidden flex flex-col gap-3 pt-10">
                {footer.subFooter?.map((item, index) => (
                  <LosseLink className="font-hh-heading text-hh-grey text-sm hover:text-white smooth" src={item?.link} key={index} />
                ))}
              </div>
            </div>
            <div className="col-span-1 hidden lg:flex justify-center">
              <div>
                <LossePlaatjie maxwidth={100} src={footer.contactFooter?.label} alt="label" />
              </div>
            </div>
          </div>
          <div className="hidden lg:flex justify-end items-center gap-12 lg:-mt-5">
            {footer.subFooter?.map((item, index) => (
              <LosseLink className="font-hh-heading text-hh-grey text-sm hover:text-white smooth" src={item?.link} key={index} />
            ))}
          </div>
        </div>
      </footer>
    </>
  )
}
