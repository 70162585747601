import { LossePlaatjie } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

export default function ContentWithCutoutImage({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <section data-component="ContentWithCutoutImage" className="relative bg-hh-concrete">
      <div className="container">
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="col-span-1 section lg:pr-10 flex flex-col  gap-6 md:gap-10 lg:gap-20">
            <Content className="content lg:content-lg children-headings:mb-4 lg:children-headings:mb-12">{fields.description}</Content>
            {fields.links && (
              <div className="flex max-md:justify-center items-center gap-2">
                {fields.links.map((link, index) => (
                  <Button
                    className={clsx(index >= 1 && 'button-mine-shaft-opaque', 'button-mine-shaft-opaque')}
                    key={index}
                    to={link?.link?.url || '/'}
                  >
                    {link?.link?.title}
                  </Button>
                ))}
              </div>
            )}
          </div>
          <div className="col-span-1 relative">
            <LossePlaatjie
              maxwidth={480}
              className="md:absolute w-full sm:w-[480px] bottom-0 left-0 right-0 mx-auto object-cover"
              src={fields.image}
              alt="block"
            />
          </div>
        </div>
      </div>
    </section>
  )
}
