import { LossePlaatjie } from '@ubo/losse-sjedel'
import { createContext, useContext } from 'react'
import Content from '~/components/elements/Content'
import type { Page_Flexcontent_Flex_Content } from '~/graphql/types'

const SectionContext = createContext<{
  fields: Page_Flexcontent_Flex_Content | null
}>({
  fields: null
})

export default function ContentSteps({ fields }: { fields: Page_Flexcontent_Flex_Content }) {
  return (
    <SectionContext.Provider value={{ fields }}>
      <section data-component="ContentSteps" className="section bg-hh-mine-shaft text-white">
        <div className="container">
          <div className="lg:hidden">
            <Mobile />
          </div>
          <div className="hidden lg:block">
            <Desktop />
          </div>
        </div>
      </section>
    </SectionContext.Provider>
  )
}

function Mobile() {
  const { fields } = useContext(SectionContext)

  return (
    <>
      {fields?.description && (
        <Content className="content children:!text-left children-headings:text-3xl pr-12 lg:w-3/5 children-p:text-hh-blue children-p:font-hh-heading children-p:text-lg">
          {fields?.description}
        </Content>
      )}
      <ul className="mt-8">
        {fields?.items?.map((edge, i) => {
          return (
            <li key={edge?.title} className="flex items-start py-5">
              <LossePlaatjie maxwidth={40} src={edge?.icon} className="mr-4 min-w-[40px] max-w-[40px] h-auto" />
              <div>
                <span className="inline-flex items-center mb-4 text-lg leading-[1.1]">
                  <span className="inline-block font-semibold font-hh-heading">
                    <span>{i + 1}. </span>
                    {edge?.title}
                  </span>
                </span>
                <Content className="text-sm">{edge?.description}</Content>
              </div>
            </li>
          )
        })}
      </ul>
    </>
  )
}

function Desktop() {
  const { fields } = useContext(SectionContext)

  return (
    <>
      <div className="flex justify-center">
        <Content className="children-headings:text-5xl children-headings:font-semibold children-p:text-hh-blue children-headings:mb-4 mb-20">
          {fields?.description}
        </Content>
      </div>
      <ul className="grid grid-cols-4 items-start gap-14">
        {fields?.items?.map((edge, i) => {
          return (
            <li key={edge?.title} className="col-span-1">
              <div className="inline-flex lg:flex-col xl:flex-row xl:items-center min-h-[56px]">
                <LossePlaatjie src={edge?.icon} className="min-w-[40px] max-w-[40px] mr-3 lg:mb-2 xl:mb-0" />
                <span className="font-semibold font-hh-heading text-lg">
                  {i + 1}. {edge?.title}
                </span>
              </div>
              <Content className="mt-5 lg:mt-2 xl:mt-5">{edge?.description}</Content>
            </li>
          )
        })}
      </ul>
    </>
  )
}
