import { useLoaderData } from '@ubo/losse-sjedel'
import { useEffect, useState } from 'react'
import Preview from '~/components/elements/Vacancies/Preview'
import type { Page_Flexcontent_Flex_Vacancies, Vacature } from '~/graphql/types'

export default function VacanciesChosen({ fields }: { fields: Page_Flexcontent_Flex_Vacancies }) {
  const { page } = useLoaderData<{ page: Vacature }>()
  const [vacancies, setVacancies] = useState(fields.vacancies?.edges)

  useEffect(() => {
    const filteredVacancies = fields.vacancies?.edges.filter((r) =>
      fields.chosenvacancies?.map((f) => f?.databaseId).includes(r.node.databaseId)
    )

    setVacancies(filteredVacancies)
  }, [fields.chosenvacancies, fields.vacancies?.edges])

  return (
    <section data-component="VacanciesChosen" className="section">
      <div className="container">
        <div className="grid lg:py-10 grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-16">
          {vacancies
            ?.filter((f) => f.node.databaseId !== page.databaseId)
            .slice(0, 2)
            .map((v, index) => {
              const vacancy = v.node as Vacature
              return <Preview key={index} fields={vacancy} />
            })}
        </div>
      </div>
    </section>
  )
}
