import clsx from 'clsx'
import { Navigation } from 'swiper/modules'
import Button from '~/components/elements/Button'
import Content from '~/components/elements/Content'
import Slider, { Slide } from '~/components/elements/Slider'
import Preview from '~/components/elements/Vacancies/Preview'
import type { Page_Flexcontent_Flex_Vacancies, Vacature } from '~/graphql/types'

export default function VacanciesLatest({ fields }: { fields: Page_Flexcontent_Flex_Vacancies }) {
  return (
    <section data-component="VacanciesLatest" className="section">
      <div className="container">
        <div className="flex flex-col gap-7 md:gap-10 lg:gap-20">
          <Content className="content md:children-h2:!text-center children-h2:!text-left md:children-p:!text-center children-p:!text-left children-p:text-base sm:children-p:text-xl lg:children-p:text-3xl children-p:font-hh-heading children-p:text-hh-blue">
            {fields.description}
          </Content>
          <div className="flex flex-col gap-7 md:gap-10">
            <div className="lg:block hidden relative">
              <div>
                <div className="swiper-button-prev-unique cursor-pointer flex items-center">
                  <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M6.1891 0.293032L7.6001 1.70703L2.31111 7.00003L7.6001 12.293L6.1851 13.707L0.90011 8.41403C0.525168 8.03898 0.314538 7.53036 0.314538 7.00003C0.314538 6.4697 0.525169 5.96109 0.90011 5.58603L6.1891 0.293032Z"
                      fill="#323232"
                    />
                  </svg>
                </div>
                <div className="swiper-button-next-unique cursor-pointer flex items-center">
                  <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M1.81139 13.707L0.400391 12.293L5.68938 6.99997L0.400391 1.70697L1.81539 0.292969L7.10038 5.58597C7.47532 5.96102 7.68595 6.46964 7.68595 6.99997C7.68595 7.5303 7.47532 8.03891 7.10038 8.41397L1.81139 13.707Z"
                      fill="#323232"
                    />
                  </svg>
                </div>
              </div>
              <Slider
                modules={[Navigation]}
                className="with-hh-arrows"
                navigation={{
                  nextEl: '.swiper-button-next-unique',
                  prevEl: '.swiper-button-prev-unique'
                }}
                breakpoints={{
                  0: {
                    slidesPerView: 2,
                    spaceBetween: 10
                  },
                  1024: {
                    slidesPerView: 2,
                    spaceBetween: 30
                  },
                  1279: {
                    slidesPerView: 2,
                    spaceBetween: 54
                  }
                }}
              >
                {fields.vacancies?.edges?.map((v, index) => {
                  const vacancy = v.node as Vacature

                  return (
                    <Slide className="!h-auto" key={`slide-${index}`}>
                      <Preview key={index} fields={vacancy} />
                    </Slide>
                  )
                })}
              </Slider>
            </div>
            <div className="lg:hidden block">
              <div className="grid grid-cols-1 gap-8 lg:gap-16">
                {fields.vacancies?.edges?.slice(0, 3).map((v, index) => {
                  const vacancy = v.node as Vacature

                  return <Preview key={index} fields={vacancy} />
                })}
              </div>
            </div>
            {fields.links && (
              <div className="flex justify-center items-center gap-2 mt-4">
                {fields.links.map((link, index) => (
                  <Button
                    className={clsx(index >= 1 && 'button-mine-shaft-opaque', 'button-mine-shaft-opaque')}
                    key={index}
                    to={link?.link?.url || '/'}
                  >
                    {link?.link?.title}
                  </Button>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
