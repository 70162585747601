import Content from '~/components/elements/Content'
import Preview from '~/components/elements/Vacancies/Preview'
import type { Page_Flexcontent_Flex_Vacancies, Vacature } from '~/graphql/types'

export default function VacanciesPerCategory({ fields }: { fields: Page_Flexcontent_Flex_Vacancies }) {
  return (
    <section data-component="VacanciesPerCategory" className="section">
      <div className="container">
        <div className="flex flex-col gap-7 md:gap-10 lg:gap-20">
          <Content className="content md:children-h2:!text-center lg:children-h2:!text-left md:children-p:!text-center lg:children-p:!text-left children-p:text-base sm:children-p:text-xl lg:children-p:text-3xl children-p:font-hh-heading children-p:text-hh-blue">
            {fields.description}
          </Content>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-4 lg:gap-7 xl:gap-14">
            {fields.vacancycategory?.[0]?.vacatures?.edges.slice(0, 2).map((item, index) => {
              const vacancy = item.node as Vacature

              return <Preview key={index} fields={vacancy} />
            })}
          </div>
        </div>
      </div>
    </section>
  )
}
