/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react'
import { LosseLink, losseContentParse, useLoaderData } from '@ubo/losse-sjedel'
import { motion, AnimatePresence } from 'framer-motion'
import HeaderSubMenu from './HeaderSubmenu'
import type { HeaderLoaderData } from './Header'
import type { Vacature } from '~/graphql/types'
import clsx from 'clsx'

export default function Menu() {
  const { header } = useLoaderData<HeaderLoaderData>()
  const [isOpen, setOpen] = useState(-1)

  return (
    <>
      {header?.menuHeader?.map((l, index) => {
        let countPerCategory = 0

        const categoryToCount = l?.vacancycategory?.databaseId

        header.vacancies?.edges.forEach((edge) => {
          const vacancy = edge.node as Vacature

          const mainCategory = vacancy.categories?.edges.filter((edge) => edge.node.parentDatabaseId === 12)[0].node.databaseId

          if (mainCategory === categoryToCount) {
            countPerCategory += 1
          }
        })


        return (
          <motion.li onHoverStart={() => setOpen(index)} onHoverEnd={() => setOpen(-1)} className="relative" key={index}>
            <div className="flex flex-wrap items-center">
              <LosseLink
                className={clsx(l?.showAsButton ? 'button-green' : 'text-white group flex relative items-center gap-5 lg:gap-1 text-2xl lg:text-base font-hh-heading uppercase font-bold')}
                to={l?.link?.url || '/'}
              >
                {losseContentParse(l?.link?.title)}
                {l?.showvacancycount && (
                  <div className="bg-hh-blue rounded-full w-[56px] h-[36px] lg:w-5 lg:h-5 text-lg lg:text-[11px] text-white font-hh-heading uppercase flex justify-center items-center">
                    {countPerCategory}
                  </div>
                )}
                <div className="w-[0%] smooth group-hover:w-full absolute -bottom-1 left-0 h-[2px] bg-hh-blue" />
              </LosseLink>
              {l?.submenu && (
                <div
                  onClick={isOpen === index ? () => setOpen(-1) : () => setOpen(index)}
                  className="ml-[15px]"
                  aria-label={`Submenu ${l?.link?.title}`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    style={
                      isOpen === index
                        ? {
                          transform: 'rotate(180deg)'
                        }
                        : {
                          transform: 'rotate(0deg)'
                        }
                    }
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#FFFFFF"
                    strokeWidth={1}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="transition-all"
                  >
                    <polyline points="6 9 12 15 18 9" />
                  </svg>
                </div>
              )}
            </div>
            {l?.submenu && isOpen === index && (
              <AnimatePresence>
                <HeaderSubMenu fields={l.submenu} />
              </AnimatePresence>
            )}
          </motion.li>
        )
      })}
    </>
  )
}
