import { LosseLink, LossePlaatjie } from '@ubo/losse-sjedel'
import Content from '~/components/elements/Content'
import type { Post as Props } from '~/graphql/types'

export default function Post({ data }: { data: Props }) {
  if (!data.title) return null

  return (
    <LosseLink to={data.uri}>
      <div className="aspect-video w-full rounded-xl overflow-hidden relative">
        <LossePlaatjie
          src={data.featuredImage?.node}
          className="w-full h-full object-cover"
          placeholder="blur"
          maxwidth={600}
          title={data.title}
        />
      </div>
      <h2 className="swiper-no-swiping text-2xl line-clamp-1">{data.title}</h2>
      <Content className="line-clamp-1">{data.recap?.excerpt}</Content>
    </LosseLink>
  )
}
