import type { Component_Info } from '~/graphql/types'

export function replaceInfoVariables(str: string, info: Component_Info) {
  return str.replace(/\{info\.(\w+)\}/g, (match, variable) => {
    if (info.hasOwnProperty(variable)) {
      // @ts-ignore
      return info[variable]
    }

    if (variable === 'total') {
      return info.vacancies?.pageInfo.total
    }

    return match
  })
}
