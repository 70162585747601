import type { ComponentPropsWithoutRef } from 'react'
import { LosseContent, losseContentToReact } from '@ubo/losse-sjedel'
import Button from '~/components/elements/Button'
import useInfo from '~/hooks/useInfo'
import type { Component_Info } from '~/graphql/types'

export default function Content({ ...rest }: ComponentPropsWithoutRef<'div'>) {
  const info = useInfo()

  let children = rest.children

  if (typeof children === 'string') {
    children = children.replace(/\{info\.(\w+)\}/g, (match: any, variable: any) => {
      if (info.hasOwnProperty(variable)) {
        return info[variable as keyof Component_Info]
      }

      if (variable === 'total') {
        return info.vacancies?.pageInfo.total
      }

      return match
    })
  }

  return (
    <LosseContent
      {...rest}
      replace={(domNode: any) => {
        const { name, attribs, children } = domNode

        if (name === 'a') {
          const attributes = { ...attribs }

          attributes.className = attributes.class
          delete attributes.class

          if (attribs.class === 'button') {
            return (
              <Button to={attributes.href} {...attributes}>
                {losseContentToReact(children)}
              </Button>
            )
          }
        }

        return undefined
      }}
    >
      {rest.children}
    </LosseContent>
  )
}
